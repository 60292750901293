<template>
  <div>
    <div class="row justify-content-end align-self-center mr-4">

      <responsive-button  v-if="!LOCK_SERVICE" class="mt-2 mx-1" variant="primary" pill  text="Registrar Manifiesto"
        responsive="lg" icon="plus-circle-fill" size="sm" @ClickEvent="OpenManifestModal()"
      />
      <b-modal size="xl"
        title="Registro de Manifiesto"
        v-model="manifestModalShow"
        @hide="CloseModal"
      >
        <b-container fluid>
          <a-form-model layout="horizontal" :model="manifestForm" :rules="manifestModalRules" ref="manifest-Form">
            <a-row align="middle" justify="center">
              <a-col :sm="{ span: 24 }" :md="{ span:12}">
                <a-form-model-item label="Número de Manifiesto" ref="number" prop="number">
                  <a-input class="block" placeholder="Número de Manifiesto" v-model="manifestForm.number"/>
                </a-form-model-item>
              </a-col>
              <a-col :sm="{ span: 24 }" :md="{ span:12}">
                <a-form-model-item label="Inicio de Descarga">
                  <a-date-picker v-model="manifestForm.initial_dated_at"
                  :show-time="{ format: 'HH:mm' }" format="YYYY-MM-DD HH:mm" placeholder="Select Time"
                  ></a-date-picker>
                </a-form-model-item>
              </a-col>
            </a-row>
            <a-row>
              <a-col :sm="{ span: 24 }" :md="{ span:12}">
                <a-form-model-item label="Recibió Inspección" class="mx-1" ref="receptor" prop="receptor">
                  <a-select v-model="manifestForm.receptor"
                    placeholder="Seleccionar"
                    :disabled="infoView"
                    show-search
                    :filter-option="filterOption"
                  >
                    <a-select-option  v-for="option in users" :key="option.id" :value="option.id" >
                      {{option.name}}
                    </a-select-option>
                  </a-select>
                </a-form-model-item>
              </a-col>
              <a-col :sm="{ span: 24 }" :md="{ span: 12}">
                <a-form-model-item label="Supervisó Inspección" class="mx-1" ref="supervisor" prop="supervisor">
                  <a-select v-model="manifestForm.supervisor"
                    placeholder="Seleccionar"
                    :disabled="infoView"
                    show-search
                    :filter-option="filterOption"
                  >
                    <a-select-option  v-for="option in users" :key="option.id" :value="option.id" >
                      {{option.name}}
                    </a-select-option>
                  </a-select>
                </a-form-model-item>
              </a-col>
            </a-row>
            <a-row v-if="Object.keys(currentService).length > 0 && (currentService.serviceTypes.name === 'Entrada por Venta' || currentService.serviceTypes.name === 'Entrada por Compra') ">
              <a-col>
                <a-form-model-item label="Recolector" class="mx-1" ref="collector_id" prop="collector_id">
                  <a-select v-model="manifestForm.collector_id" placeholder="Seleccionar" >
                    <a-select-option  v-for="option in collectors" :key="option.jsonApi.id" :value="option.jsonApi.id" >
                      {{option.name}}
                    </a-select-option>
                  </a-select>
                </a-form-model-item>
              </a-col>
            </a-row>
            <hr class="bg-primary">
            <a-row>
              <a-col class="pt-0" :sm="{ span: 24 }" :md="{ span:12 }">
                <a-form-model-item>
                  <a-checkbox v-model="manifestForm.lab_checks">
                    <strong class="text-primary">Requiere Autorización de Laboratorio para Descargar</strong>
                  </a-checkbox>
                </a-form-model-item>
              </a-col>
            </a-row>
            <a-row v-show="!load">
              <div class="col-md-4 offset-md-4"  v-if="!LOCK_SERVICE">
                <responsive-button block variant="primary" pill size="md"
                  text="Guardar" responsive="md" icon="archive-fill" @ClickEvent="OnSubmit" class="mr-2"
                />
              </div>
            </a-row>
            <my-spinner :load="load"></my-spinner>
          </a-form-model>
        </b-container>
        <template v-slot:modal-footer>
          <simple-modal-footer @ClickEvent="CloseManifestModal"/>
        </template>
      </b-modal>
    </div>
    <manifest-table
      v-model="tableObject"
      @Modal="OpenManifestModal"
      @Filter="GetData()"
      @Delete="DeleteManifest($event)"
      class="mt-3"
    />
  </div>
</template>

<script>
import 'moment/locale/es'
import moment from 'moment'
import api from '@/services/axios/index'
import { Modal } from 'ant-design-vue'
import ManifestTable from './manifestTable.vue'
import { getGeneralMixin } from '@/mixins/getGeneralMixin'
import { manifestMixin } from '@/mixins/core/manifestMixin'
import { mapGetters, mapState } from 'vuex'
export default {
  name: 'ManifestForm',
  mixins: [
    getGeneralMixin,
    manifestMixin,
  ],
  components: {
    ManifestTable,
  },
  props: {
    infoView: {
      default: false,
      type: [Boolean],
    },
  },
  data() {
    return {
      // ************************************
      // ************************************
      // Variables globales
      load: false,
      manifestModalShow: false,
      serviceDetailModalShow: false,
      manifest: {
        id: '0',
        number: undefined,
        service_planning_id: this.$route.params.folio,
        lab_checks: undefined,
        details: undefined,
        initial_dated_at: moment(),
        final_dated_at: moment(),
        supervisor: undefined,
        receptor: undefined,
      },
      // Variables globales
      // ************************************
      // ************************************
      // Variables del Formulario del Modal
      id: '0',
      manifestForm: {
        id: '0',
        number: undefined,
        service_planning_id: this.$route.params.folio,
        collector_id: undefined,
        lab_checks: false,
        details: '',
        initial_dated_at: moment(),
        final_dated_at: moment(),
        supervisor: undefined,
        receptor: undefined,
      },
      manifestModalRules: {
        receptor: [
          { required: true, message: 'El campo del personal de recepción es obligatorio.', trigger: 'blur' },
        ],
        supervisor: [
          { required: true, message: 'El campo del personal de supervisión es obligatorio.', trigger: 'blur' },
        ],
        number: [
          { required: true, message: 'El campo Número de Manifiesto es obligatorio.', trigger: 'blur' },
        ],
      },
      // Variables del Formulario del Modal
      // ************************************
      // ************************************
      // Tabla Tablas del Proceso
      serviceDetailModalService: {},
      // Tabla Tablas del Proceso
      // ************************************
      // ************************************
      // Catalogs Data
      users: [],
      collectors: [],
      // Catalogs Data
      // ************************************
      //
    }
  },
  computed: {
    ...mapState(['servicePlannings']),
    ...mapGetters('servicePlannings', ['LOCK_SERVICE']),
    currentService() {
      return this.$store.state.servicePlannings.currentService
    },
  },
  methods: {
    // ************************************
    // ************************************
    // Modals functions
    OpenManifestModal(data = {}) {
      this.manifestModalShow = true
      if (Object.keys(data).length !== 0) {
        this.manifestForm = {
          id: data.id,
          // id: data.jsonApi.id,
          number: data.number,
          service_planning_id: this.$route.params.folio,
          lab_checks: data.lab_checks,
          details: data.details,
          initial_dated_at: moment(data.initial_dated_at),
          final_dated_at: moment(data.final_dated_at),
          supervisor: `${data.supervisor.user_id}`,
          receptor: `${data.recepcionist.user_id}`,
        }
      }
      this.UserCatalog()
      if (this.currentService.serviceTypes.name === 'Entrada por Venta' || this.currentService.serviceTypes.name === 'Entrada por Compra') {
        this.GetCollectors()
      }
    },
    CloseManifestModal() {
      this.manifestModalShow = false
      this.manifestForm = {
        id: '0',
        number: undefined,
        service_planning_id: this.$route.params.folio,
        lab_checks: undefined,
        details: undefined,
        initial_dated_at: moment(),
        final_dated_at: moment(),
        supervisor: undefined,
        receptor: undefined,
        collector_id: undefined,
      }
    },
    // TODO: CREATE DE REAL INFO FOR STAGE MODAL
    OpenServiceDetailModal(data = {}) {
      this.serviceDetailModalShow = true
      this.serviceDetailModalService = {
        folio: 'ABC',
        client_name: 'Cliente 1',
        current: 'Sólidos',
        unit_type: 'Camión tipo Torton',
        program_weight: 22,
        um: 'T',
        transport: 'Cliente',
        date_hour: '2019-12-24T02:55:01+00:00',
        service_type: 'Entrada',
        service_stage: 'Generación del Servicio(25%)',
        program_status: 'Programado',
      }
    },
    CloseServiceDetailModal() {
      this.serviceDetailModalShow = false
      this.serviceDetailModalService = {}
    },
    OnSubmit() {
      this.$refs['manifest-Form'].validate(valid => {
        if (valid) {
          this.load = true
          const newManifestForm = this.manifestForm
          const request = {
            number: newManifestForm.number,
            service_planning_id: newManifestForm.service_planning_id,
            recepcionist: newManifestForm.receptor,
            supervisor: newManifestForm.supervisor,
            initial_dated_at: moment(newManifestForm.initial_dated_at).format('YYYY-MM-DD HH:mm'),
            lab_checks: newManifestForm.lab_checks,
            collector_id: (this.manifestForm.collector_id !== undefined) ? this.manifestForm.collector_id : null,
          }
          if (this.id === '0') {
            api.post('/specialManifests', request)
              .then(response => {
                if (response !== undefined) {
                  Modal.success({
                    title: '¡Guardado!',
                    content: 'La información general de Planeación de Servicios se ha almacenado exitosamente',
                    zIndex: 3000,
                  })
                  this.GetData()
                  this.ResetData()
                  this.CloseManifestModal()
                }
                this.load = false
              }).catch(error => {
                console.log(error)
                this.load = false
              })
          } else {
            // Aquí irá la funcionalidad de actualización
          }
        } else {
          return false
        }
      })
    },
    ResetData() {
      this.manifestForm = {
        number: undefined,
        service_planning_id: this.$route.params.folio,
        lab_checks: undefined,
        details: undefined,
        initial_dated_at: moment(),
        final_dated_at: moment(),
        supervisor: undefined,
        receptor: undefined,
      }
    },
    CloseModal() {
      this.$emit('Close')
    },
    // Function Modals
    // ************************************
    // ************************************
    async GetData(paginationEvent = {}) {
      await this.get(paginationEvent)
    },
    async DeleteManifest(id) {
      await this.deleteQuestion(this.resourceType, id, this.GetData, null)
    },
    // ************************************
    // ************************************
    // CATALOGS
    async GetCatalogs() {
      await this.UserCatalog()
      await this.GetCollectors()
    },
    async GetCollectors() {
      let params = {}
      if (Object.keys(this.currentService).length !== 0) {
        if (this.currentService.service_planeable.jsonApi.type === 'clientContracts') {
          await this.getSpecificResourceData({ include: 'clients' }, 'clientContracts', this.currentService.service_planeable.jsonApi.id)
          params = {
            sort: 'name',
            'filter[clients]': `${this.specificResponse.clients.jsonApi.id}`,
          }
        } else if (this.currentService.service_planeable.jsonApi.type === 'clients') {
          params = {
            sort: 'name',
            'filter[clients]': `${this.currentService.service_planeable.clients.jsonApi.id}`,
          }
        }
        await this.getResourceData(params, 'collectors')
      }
      this.collectors = this.generalResponse
      this.generalResponse = []
    },
    UserCatalog: async function () {
      const params = {
        sort: 'name',
      }
      this.users = await this.GetResource('/users', params)
    },
    // CATALOGS
    // ************************************
    // ************************************
  },
  mounted() {
    this.GetCatalogs()
    this.filterValueManifest = this.$route.params.folio
    this.GetData()
  },
}
</script>

<style>

</style>
