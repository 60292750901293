<template>
<div>
  <div class="air__utils__heading">
    <h5>Descarga de Materiales</h5>
  </div>
  <my-breadcrumbs :routes="routes"/>
  <!-- NAV TOOLBAR -->
  <servicePlanningNavToolbar v-if="serviceID !== '0'" :serviceId="serviceID"/>
  <!-- NAV TOOLBAR -->
  <div class="row justify-content-end my-2 p-3 bg-white">
    <div class="col-3">
      <b-button pill variant="info" @click="UpdateServicePlanningDeparture">
        <b-icon icon="clock"/>
        Actualizar Fecha de Descarga
      </b-button>
    </div>
  </div>

  <div class="row mb-5">
    <div class="col">
      <a-tabs defaultActiveKey="2" type="card" size="large">
        <!--  Registro de Manifiesto -->
        <a-tab-pane key="2">
          <span slot="tab">
            <a-icon type="file-exclamation" />
            Registro de Manifiesto
          </span>
          <div class="card">
            <div class="card-body text-center">
              <manifest-form/>
            </div>
          </div>
        </a-tab-pane>
        <!--  Inspeccion del Residuo -->
        <a-tab-pane key="3">
          <span slot="tab">
            <a-icon type="file-done" />
            Inspección del Residuo
          </span>
          <div class="card">
            <div class="card-body text-center">
              <material-inspection-form :typeForm="'inspectionMaterial'"/>
            </div>
          </div>
        </a-tab-pane>
        <!--  Inspeccion de Descarga -->
        <a-tab-pane key="4">
          <span slot="tab">
            <a-icon type="file-search" />
            Inspección de Descarga
          </span>
          <div class="card">
            <div class="card-body">
              <a-tabs defaultActiveKey="2" type="card" size="large">
                <a-tab-pane key="1">
                  <span slot="tab">
                    <a-icon type="check-circle" />
                    Check de Descarga
                  </span>
                  <div class="card">
                    <div class="card-body">
                      <!-- TABLA DE CARRIERS -->
                      <servicePlanningCarrierTable :serviceId="serviceID" :tableObject="tableCarrier"
                                                   @SelectCarrier="SelectCarrier" :view="['select']"/>
                      <!-- TABLA DE CARRIERS -->
                    </div>
                  </div>
                </a-tab-pane>
                <a-tab-pane key="2">
                  <span slot="tab">
                    <a-icon type="file-search" />
                    Inspección de Descarga
                  </span>
                  <div class="card">
                    <div class="card-body">
                      <material-inspection-form :typeForm="'inspectionDischarge'"></material-inspection-form>
                    </div>
                  </div>
                </a-tab-pane>
              </a-tabs>
            </div>
          </div>
        </a-tab-pane>
        <!--  Tipo de Muestreo -->
        <a-tab-pane key="5">
          <span slot="tab">
            <a-icon type="experiment" />
            Tipo de Muestreo
          </span>
          <div class="card">
            <div class="card-body text-center">
              <material-inspection-form :typeForm="'dischargeLab'"></material-inspection-form>
            </div>
          </div>
        </a-tab-pane>
        <!--  Incidencias -->
        <a-tab-pane key="6">
          <span slot="tab">
            <a-icon type="warning" />
            Incidencias
          </span>
          <div class="card">
            <div class="card-body text-center">
              <incidentForm :serviceId="serviceID"/>
            </div>
          </div>
        </a-tab-pane>
      </a-tabs>
    </div>
  </div>
</div>
</template>

<script>
import servicePlanningNavToolbar from '../components/servicePlanningNavToolbar'
import ManifestForm from '../components/discharge/manifestForm.vue'
import MaterialInspectionForm from '../components/discharge/materialInspectionForm.vue'
import incidentForm from '../../incidents/create_incidents/createIncidentTable'
import { objectArray, resolvePagination } from '@/helpers/jsonApiTransformer'
import moment from 'moment'
import { Modal, notification } from 'ant-design-vue'
import { getGeneralMixin } from '@/mixins/getGeneralMixin'
import { apiRestMixin } from '@/mixins/apiRestMixin'
import servicePlanningCarrierTable from '../components/servicePlanningCarrierTable'
export default {
  name: 'discharge',
  components: {
    MaterialInspectionForm,
    ManifestForm,
    servicePlanningCarrierTable,
    servicePlanningNavToolbar,
    incidentForm,
  },
  mixins: [getGeneralMixin, apiRestMixin],
  data() {
    return {
      // Params de ruta
      serviceID: this.$route.params.folio,
      // ************************************
      // ************************************
      // Variables Generales del Proceso
      routes: [
        {
          name: 'home',
          breadcrumbName: 'Inicio',
          bIcon: 'house-fill',
        },
        {
          breadcrumbName: 'Planeación de Servicios',
          aIcon: '',
          name: 'service_plannings',
        },
        {
          breadcrumbName: 'Descarga de Materiales',
          aIcon: '',
        },
      ],
      // Variables Generales del Proceso
      // ************************************
      // ************************************
      //
      // ************************************
      // ************************************
      // Variables de Modales
      manifestModalShow: false,
      laboratoryModalShow: false,
      serviceDetailModalShow: false,
      service_folio: 'ABC',
      serviceDetailModalService: {},
      // Variables de Modales
      // ************************************
      // ************************************
      //
      //  ----- Forms -----//
      residueInspectionForm: {
        received: '',
        supervised: '',
        requiresAuthorization: false,
        manifestNumber: '',
      },
      residueInspectionRules: {
        received: [
          { required: true, message: 'El campo Recibió es obligatorio.', trigger: 'blur' },
        ],
      },
      residueInspectionLoad: false,
      // ************************************
      // ************************************
      // Tabla Tablas del Proceso
      tableObjectManifest: {
        data: [],
        columns: [
          {
            title: 'Número de Manifiesto',
            dataIndex: 'number',
            key: 'number',
            scopedSlots: {
              filterDropdown: 'filterDropdown',
              filterIcon: 'filterIcon',
              customRender: 'customRender',
            },
            onFilter: (value, record) => record.number.toLowerCase().includes(value.toLowerCase()),
            onFilterDropdownVisibleChange: (visible) => {
              if (visible) {
                setTimeout(() => {
                  this.searchInput.focus()
                }, 0)
              }
            },
          },
          {
            title: 'Recibió Inspección',
            dataIndex: 'name',
            key: 'name',
            scopedSlots: {
              filterDropdown: 'filterDropdown',
              filterIcon: 'filterIcon',
              customRender: 'customRender',
            },
            onFilter: (value, record) => record.name.toLowerCase().includes(value.toLowerCase()),
            onFilterDropdownVisibleChange: (visible) => {
              if (visible) {
                setTimeout(() => {
                  this.searchInput.focus()
                }, 0)
              }
            },
          },
          {
            title: 'Supervisó Inspección',
            dataIndex: 'name',
            key: 'name',
            scopedSlots: {
              filterDropdown: 'filterDropdown',
              filterIcon: 'filterIcon',
              customRender: 'customRender',
            },
            onFilter: (value, record) => record.name.toLowerCase().includes(value.toLowerCase()),
            onFilterDropdownVisibleChange: (visible) => {
              if (visible) {
                setTimeout(() => {
                  this.searchInput.focus()
                }, 0)
              }
            },
          },
          {
            title: 'Inicio de Descarga',
            dataIndex: 'initial_dated_at',
            key: 'initial_dated_at',
            scopedSlots: {
              customRender: 'customRender',
            },
          },
          {
            title: 'Acción',
            dataIndex: '',
            class: 'text-center',
            key: 'x',
            scopedSlots: {
              customRender: 'action',
            },
          },
        ],
        loading: false,
        pagination: {
          current: 1,
          pageSize: 15,
          from: 0,
          to: 0,
          total: 30,
          lastPage: 0,
        },
      },
      // Tabla Tablas del Proceso
      // ************************************
      // ************************************
      tableObjectWaste: {
        data: [
          {
            id: '1',
            waste_name: 'Lodos a Granel',
            manifested_number: '58794127',
            current: 'SO',
            manifested_quantity: '80',
            manifested_quantity_um: 'TON',
            real_quantity: '75',
            real_quantity_um: 'TON',
            diff_quantity: '5',
            diff_quantity_um: 'TON',
            container_type: 'Tambo',
            container_quantity: '4',
            incidents: 'EI',
            handle_type: 'Reciclaje',
          },
          {
            id: '2',
            waste_name: 'Lodos a Granel',
            manifested_number: '58794127',
            current: 'SO',
            manifested_quantity: '80',
            manifested_quantity_um: 'TON',
            real_quantity: '75',
            real_quantity_um: 'TON',
            diff_quantity: '5',
            diff_quantity_um: 'TON',
            container_type: 'Tambo',
            container_quantity: '4',
            incidents: 'EI',
            handle_type: 'Reciclaje',
          },
        ],
        loading: false,
        pagination: {
          current: 1,
          pageSize: 15,
          from: 0,
          to: 0,
          total: 30,
          lastPage: 0,
        },
      },
      // Inspeccion de descarga
      downloadInspectionForm: {
        name: '',
        date: moment(),
        hour: moment(),
        comments: '',
      },
      downloadInspectionRules: {
        name: [
          { required: true, message: 'El campo Nombre es obligatorio.', trigger: 'blur' },
        ],
      },
      downloadInspectionLoad: false,
      // Tipo de Muestreo
      materialForm: {
        received: '',
        supervised: '',
        requiresAuthorization: false,
        manifestNumber: '',
        date: moment(),
      },
      materialRules: {
        received: [
          { required: true, message: 'El campo Recibió es obligatorio.', trigger: 'blur' },
        ],
      },
      materialLoad: false,
      tableObjectMaterials: {
        data: [
          {
            id: '1',
            current: 'Corriente',
            material_code: 'CODE768',
            material_name: 'Material',
            manifested_material: 'Mat Manifs',
            um: 'TON',
            real_quantity_received: 35,
            inspection_type: 'Tipo',
          },
        ],
        loading: false,
        pagination: {
          current: 1,
          pageSize: 15,
          from: 0,
          to: 0,
          total: 30,
          lastPage: 0,
        },
      },
      sampleControlView: false,
      // CheckList Descarga
      // ServicePlanningCarriersTable
      tableCarrier: {
        data: [],
        loading: false,
        perPageValue: 15,
        currentPageValue: 1,
        pagination: {
          current: 1,
          pageSize: 15,
          from: 0,
          to: 0,
          total: 30,
          lastPage: 0,
        },
      },
      // ServicePlanningCarriersTable
      // CheckList Descarga
    }
  },
  methods: {
    // CheckList
    SelectCarrier: function (data) {
      const spId = this.$route.params.folio
      const spCarrierId = data.jsonApi.id

      this.$router.push({
        name: 'check_discharge',
        params: {
          spId: spId,
          spCarrierId: spCarrierId,
        },
      })
    },
    CloseServiceDetailModal() {
      this.serviceDetailModalShow = false
      this.serviceDetailModalService = {}
    },
    // Inspeccion de descarga
    onConfirmDownload: function () {
      Modal.confirm({
        title: '¿Está seguro de realizar la Autorización?"',
        // content: 'Esta operación es irreversible',
        okText: 'Si',
        okType: 'primary',
        cancelText: 'No',
        cancelType: 'secondary',
        zIndex: 3000,
        onOk() {
          notification.success({
            message: 'Descarga autorizada',
          })
        },
        onCancel() {
          notification.info({
            message: 'No se ha autorizado la descarga',
          })
        },
      })
    },
    onCancelDownload() {
      Modal.confirm({
        title: '¿Está seguro de cancelar la Descarga?"',
        // content: 'Esta operación es irreversible',
        okText: 'Cancelar',
        okType: 'primary',
        cancelText: 'No',
        cancelType: 'secondary',
        zIndex: 3000,
        onOk() {
          notification.success({
            message: 'Descarga cancelada',
          })
        },
        onCancel() {
          notification.info({
            message: 'No se ha cancelado la descarga',
          })
        },
      })
    },
    getDataMaterials(paginationEvent = {}) {
      if (Object.keys(paginationEvent).length !== 0) {
        this.tableObject.pagination.current = paginationEvent.current
        this.tableObject.pagination.pageSize = paginationEvent.pageSize
      }

      this.tableObject.loading = true
      this.$store.dispatch('jv/get', ['waste', {
        params: {
          'page[number]': `${this.tableObject.pagination.current}`,
          'page[size]': `${this.tableObject.pagination.pageSize}`,
        },
      }])
        .then(response => {
          this.tableObject.loading = false
          const jsonApiPagination = response.jsonApi.json.meta.page
          const tableData = response
          this.tableObject.data = objectArray(tableData)
          this.tableObject.pagination = resolvePagination(jsonApiPagination)
        })
    },
    // Tipo de Muestreo
    showMaterialsClassification () {
      const me = this
      Modal.confirm({
        title: '¿Esta seguro de iniciar la Clasificación de Materiales?',
        okText: 'Sí',
        okType: 'primary',
        cancelText: 'No',
        zIndex: 3000,
        onOk() {
          me.sampleControlView = true
        },
        onCancel() {
          notification.info({
            message: 'No se ha iniciado la clasificación de materiales',
          })
        },
      })
    },
    showMaterialList () {
      const me = this
      Modal.confirm({
        title: '¿Esta seguro de concluir la Clasificación de Materiales?',
        okText: 'Sí',
        okType: 'danger',
        cancelText: 'No',
        cancleType: 'secondary',
        zIndex: 3000,
        onOk() {
          me.sampleControlView = false
        },
        onCancel() {
          console.log('Cancelado')
        },
      })
    },
    // Update service_planning discharge_date ans hour
    UpdateServicePlanningDeparture() {
      const me = this
      Modal.confirm({
        title: '¿Esta seguro de actualizar  la Actualizar Fecha de Descarga del Servicio?',
        okText: 'Sí',
        okType: 'primary',
        cancelText: 'No',
        cancleType: 'secondary',
        zIndex: 3000,
        onOk() {
          me.postAxiosResource('/UpdateServicePlanningDeparture', {
            programming_code: me.serviceID,
          }, () => {
            notification.success({
              message: 'Fecha de Descarga Actualizada',
            })
          })
        },
        onCancel() {
          notification.info({
            message: 'No se ha realizado la actualización',
          })
        },
      })
    },
  },
  async mounted() {
    this.SET_SERVICEID(this.$route.params.folio)
    await this.getServiceAndSetInState(this.serviceID)
  },
}
</script>

<style scoped>
.border-w-2 {
  border-width:2px !important;
}
</style>
