import { fractalMixin } from '@/mixins/fractalMixin'

export const manifestMixin = {
  data() {
    return {
      resourceType: 'manifests',
      filterValueManifest: undefined,
      tableObject: {
        data: [],
        columns: [
          {
            title: 'Número de Manifiesto',
            dataIndex: 'number',
            key: 'number',
            class: 'text-center',
            scopedSlots: {
              filterDropdown: 'filterDropdown',
              filterIcon: 'filterIcon',
              customRender: 'customRender',
            },
            onFilter: (value, record) => record.number.toLowerCase().includes(value.toLowerCase()),
            onFilterDropdownVisibleChange: (visible) => {
              if (visible) {
                setTimeout(() => {
                  this.searchInput.focus()
                }, 0)
              }
            },
          },
          {
            title: 'Recibió Inspección',
            dataIndex: '',
            key: 'recepcionist',
            class: 'text-center',
            scopedSlots: {
              filterDropdown: 'filterDropdown',
              filterIcon: 'filterIcon',
              customRender: 'recepcionist',
            },
            onFilter: (value, record) => record.name.toLowerCase().includes(value.toLowerCase()),
            onFilterDropdownVisibleChange: (visible) => {
              if (visible) {
                setTimeout(() => {
                  this.searchInput.focus()
                }, 0)
              }
            },
          },
          {
            title: 'Supervisó Inspección',
            dataIndex: '',
            key: 'supervisor',
            class: 'text-center',
            scopedSlots: {
              filterDropdown: 'filterDropdown',
              filterIcon: 'filterIcon',
              customRender: 'supervisor',
            },
            onFilter: (value, record) => record.name.toLowerCase().includes(value.toLowerCase()),
            onFilterDropdownVisibleChange: (visible) => {
              if (visible) {
                setTimeout(() => {
                  this.searchInput.focus()
                }, 0)
              }
            },
          },
          {
            title: 'Inicio de Descarga',
            dataIndex: 'initial_dated_at',
            key: 'initial_dated_at',
            class: 'text-center',
            scopedSlots: {
              customRender: 'dated_at',
            },
          },
          {
            title: 'Autorización de Laboratorio',
            dataIndex: '',
            key: 'lab_checks',
            class: 'text-center',
            scopedSlots: {
              customRender: 'lab',
            },
          },
          {
            title: 'Recolector',
            dataIndex: '',
            key: 'collectors',
            class: 'text-center',
            scopedSlots: {
              customRender: 'collectors',
            },
          },
          {
            title: 'Descarga cerrada',
            dataIndex: 'is_discharge_close',
            key: 'is_discharge_close',
            class: 'text-center',
            scopedSlots: {
              customRender: 'is_discharge_closes',
            },
          },
          {
            title: 'Acción',
            dataIndex: '',
            class: 'text-center',
            key: 'x',
            scopedSlots: {
              customRender: 'action',
            },
          },
        ],
        loading: false,
        pagination: {
          current: 1,
          pageSize: 15,
          from: 0,
          to: 0,
          total: 30,
          lastPage: 0,
        },
      },
    }
  },
  mixins: [fractalMixin],
  methods: {
    // Get data
    async get(pagEvent = {}) {
      const params = {
        'page[number]': (Object.keys(pagEvent).length !== 0) ? `${this.tableObject.pagination.current}` : `${this.tableObject.currentPage}`,
        'page[size]': `${this.tableObject.pagination.pageSize}`,
        sort: 'code',
        include: 'servicePlannings,collectors',
        'filter[servicePlannings]': `${this.filterValueManifest}`,
      }
      await this.GetTableResource(pagEvent, this.resourceType, this.tableObject, params)
    },
  },
}
