<template>
  <div>
    <div class="row justify-content-end align-self-center" v-if="!LOCK_SERVICE">
      <div class="col-2">
        <responsive-button
          block
          pill
          size="sm"
          responsive="lg"
          icon="lock-fill"
          class="mt-2 mx-1"
          variant="warning"
          text="Cerrar Descarga"
          @ClickEvent="openWarehouseModal"
          v-show="typeForm === 'inspectionDischarge' && isDischargeClose !== true"
        />
      </div>

      <b-modal
        size="xl"
        @hide="closeWarehouseModal"
        v-model="warehouseModalShow"
        title="Registro de Entrada a Almacén"
      >
        <b-container fluid>
          <warehouse-input-transaction-form
            type="Entrada"
            @getData="getData"
            @closeWarehouseModal="closeWarehouseModal"
            :manifestId="dischargeMaterialForm.manifest_id"
          />
        </b-container>
        <template v-slot:modal-footer>
          <simple-modal-footer @ClickEvent="closeWarehouseModal"/>
        </template>
      </b-modal>

      <div class="col-2" v-show="typeForm === 'dischargeLab' && (labAffected ==='0' || labAffected ===0 || labAffected ==='false' || labAffected ===false)">
        <responsive-button block class="mt-2 mx-1" variant="warning" size="sm" pill icon="check" text="Afectar Laboratorio" @ClickEvent="affectLaboratory"
         responsive="lg"/>
      </div>
      <div class="col-2">
        <responsive-button block class="mt-2 mx-1" variant="success" size="sm" pill icon="arrow-repeat" text="Actualizar" @ClickEvent="getData"
          responsive="lg"/>
      </div>
      <div class="col-2" v-show="dischargeMaterialForm.manifest_number !== undefined && typeForm === 'inspectionMaterial'">
        <responsive-button block class="mt-2 mx-1" variant="primary" pill  text="Registrar Residuo"
          responsive="lg" icon="plus-circle-fill" size="sm" @ClickEvent="OpenMaterialModal('create')"/>
      </div>
      <b-modal size="xl"
       :title="(bShowOperation === true) ? 'Validación de Operaciones' : (bShowLab === true) ? 'Validación de Laboratorio' : 'Registro de Residuos'"
       v-model="modalShow"
      >
        <b-container fluid>
          <div class="row" v-show="bShowOperation">
            <div class="col-lg-3 offset-lg-9 col-12 offset-0">
              <responsive-button block class="mt-2 mx-1" variant="bg-blue" pill  text="Reestablecer"
                                 responsive="lg" icon="arrow-repeat" size="sm" @ClickEvent="checkOperation = 'Sin Autorización'"/>
            </div>
          </div>
          <div class="row" v-show="bShowLab">
            <div class="col-lg-3 offset-lg-9 col-12 offset-0">
              <responsive-button block class="mt-2 mx-1" variant="bg-blue" pill  text="Reestablecer"
                                 responsive="lg" icon="arrow-repeat" size="sm" @ClickEvent="checkLab = 'Sin Prueba'"/>
            </div>
          </div>
          <a-row v-show="bShowOperation" class="text-center">
            <a-row span="24">
              <a-form-model-item class="mt-2" v-show="checkOperation === 'Sin Autorización'">
                <b-button-group>
                  <b-button class="mx-1" variant="success" pill size="lg" v-b-tooltip.hover title="Autorizar" @click="checkOperation = 'Autorizado'">
                    <b-icon icon="check" font-scale="6"/>
                  </b-button>
                  <b-button class="mx-1" variant="warning" pill size="lg" v-b-tooltip.hover title="Cuarentena" @click="checkOperation = 'Cuarentena'">
                    <b-icon icon="exclamation-triangle-fill" font-scale="6"/>
                  </b-button>
                  <b-button class="mx-1" variant="danger" pill size="lg" v-b-tooltip.hover title="Rechazar" @click="checkOperation = 'Rechazado'">
                    <b-icon icon="x" font-scale="6"/>
                  </b-button>
                </b-button-group>
              </a-form-model-item>

              <a-form-model-item class="mt-2" v-show="checkOperation === 'Autorizado'">
                <b-button-group>
                  <b-button class="mx-1" variant="success" pill size="lg" v-b-tooltip.hover title="Autorizar" @click="checkOperation = 'Autorizado'">
                    <b-icon icon="check" font-scale="6"/>
                  </b-button>
                </b-button-group>
              </a-form-model-item>

              <a-form-model-item class="mt-2" v-show="checkOperation === 'Rechazado'">
                <b-button-group>
                  <b-button class="mx-1" variant="danger" pill size="lg" v-b-tooltip.hover title="Rechazar" @click="checkOperation = 'Rechazado'">
                    <b-icon icon="x" font-scale="6"/>
                  </b-button>
                </b-button-group>
              </a-form-model-item>

              <a-form-model-item class="mt-2" v-show="checkOperation === 'Cuarentena'">
                <b-button-group>
                  <b-button class="mx-1" variant="warning" pill size="lg" v-b-tooltip.hover title="Cuarentena" @click="checkOperation = 'Cuarentena'">
                    <b-icon icon="exclamation-triangle-fill" font-scale="6"/>
                  </b-button>
                </b-button-group>
              </a-form-model-item>

            </a-row>
            <a-row span="24" class="mb-4">
              <a-row class="mt-4" v-show="!load">
                <div class="col-md-4 offset-md-4">
                  <responsive-button block variant="primary" pill size="md"
                                     text="Guardar" responsive="md"
                                     icon="archive-fill"
                                     @ClickEvent="CheckUpdateFromModal('Discharge')"
                                     class="mr-2"
                  />
                </div>
              </a-row>
              <my-spinner :load="load"></my-spinner>
            </a-row>
            <hr class="bg-primary">
            <a-row>
              <a-form-model layout="horizontal" :model="dischargeMaterialForm" :rules="dischargeMaterialRules" ref="discharge-Form">
              <a-row align="middle" justify="center">
                <a-col :sm="{ span: 24 }" :md="{ span:12}">
                  <a-form-model-item label="Número de Manifiesto" ref="number" prop="number">
                    <a-input class="block text-center" placeholder="Número de Manifiesto" v-model="dischargeMaterialForm.manifest_number" disabled/>
                  </a-form-model-item>
                </a-col>
                <a-col :sm="{ span: 24 }" :md="{ span:12}">
                  <a-form-model-item label="Ticket de Báscula" ref="weight_registry_id" prop="weight_registry_id">
                    <a-select v-model="dischargeMaterialForm.weight_registry_id" placeholder="Seleccionar" disabled>
                      <a-select-option  v-for="option in weightRegistries" :key="option.jsonApi.id" :value="option.jsonApi.id" >
                        Ticket entrada: {{option.entry_ticket}} | Ticket salida: {{option.exit_ticket}}
                      </a-select-option>
                    </a-select>
                  </a-form-model-item>
                </a-col>
                <a-col span="24">
                  <a-form-model-item label="Relación de Residuo de Operación con SAP" ref="sap_material_contract_id" prop="sap_material_contract_id">
                    <a-select v-model="dischargeMaterialForm.sap_material_contract_id" placeholder="Seleccionar"
                              show-search
                              option-filter-prop="children"
                              :filter-option="filterOption" class="small"
                              disabled
                    >
                      <a-select-option  v-for="option in sapMaterialContracts" :key="option.jsonApi.id" :value="option.jsonApi.id">
                        Residuo: {{option.materials.name}} | SAP: {{option.sapMaterials.code}} - {{option.sapMaterials.name}}
                      </a-select-option>
                    </a-select>
                  </a-form-model-item>
                </a-col>
              </a-row>
            </a-form-model>
            </a-row>
          </a-row>
          <a-row v-show="bShowLab" class="text-center">
            <a-row span="24">
              <a-form-model-item class="mt-2" v-show="checkLab === 'Sin Prueba'">
                <b-button-group>
                  <b-button class="mx-1" variant="secondary" pill size="lg" v-b-tooltip.hover title="Muestreo" @click="checkLab = 'Muestreo'">
                    <b-icon icon="calculator-fill" font-scale="6"/>
                  </b-button>
                  <b-button class="mx-1" variant="primary" pill size="lg" v-b-tooltip.hover title="Inspección Visual" @click="checkLab = 'Inspección Visual'">
                    <b-icon icon="binoculars-fill" font-scale="6"/>
                  </b-button>
                </b-button-group>
              </a-form-model-item>

              <a-form-model-item class="mt-2" v-show="checkLab === 'Muestreo'">
                <b-button-group>
                  <b-button class="mx-1" variant="secondary" pill size="lg" v-b-tooltip.hover title="Muestreo">
                    <b-icon icon="calculator-fill" font-scale="6"/>
                  </b-button>
                </b-button-group>
              </a-form-model-item>

              <a-form-model-item class="mt-2" v-show="checkLab === 'Inspección Visual'">
                <b-button-group>
                  <b-button class="mx-1" variant="primary" pill size="lg" v-b-tooltip.hover title="Inspección Visual">
                    <b-icon icon="binoculars-fill" font-scale="6"/>
                  </b-button>
                </b-button-group>
              </a-form-model-item>
            </a-row>
            <a-row span="24" class="mb-4">
              <a-row class="mt-4" v-show="!load">
                <div class="col-md-4 offset-md-4">
                  <responsive-button block variant="primary" pill size="md"
                                     text="Guardar" responsive="md"
                                     icon="archive-fill"
                                     @ClickEvent="CheckUpdateFromModal('Laboratory')"
                                     class="mr-2"
                  />
                </div>
              </a-row>
              <my-spinner :load="load"></my-spinner>
            </a-row>
            <hr class="bg-primary">
            <a-row>
              <a-form-model layout="horizontal" :model="dischargeMaterialForm" :rules="dischargeMaterialRules" ref="discharge-Form">
                <a-row align="middle" justify="center">
                  <a-col :sm="{ span: 24 }" :md="{ span:12}">
                    <a-form-model-item label="Número de Manifiesto" ref="number" prop="number">
                      <a-input class="block text-center" placeholder="Número de Manifiesto" v-model="dischargeMaterialForm.manifest_number" disabled/>
                    </a-form-model-item>
                  </a-col>
                  <a-col :sm="{ span: 24 }" :md="{ span:12}">
                    <a-form-model-item label="Ticket de Báscula" ref="weight_registry_id" prop="weight_registry_id">
                      <a-select v-model="dischargeMaterialForm.weight_registry_id" placeholder="Seleccionar" disabled>
                        <a-select-option  v-for="option in weightRegistries" :key="option.jsonApi.id" :value="option.jsonApi.id" >
                          Ticket entrada: {{option.entry_ticket}} | Ticket salida: {{option.exit_ticket}}
                        </a-select-option>
                      </a-select>
                    </a-form-model-item>
                  </a-col>
                  <a-col span="24">
                    <a-form-model-item label="Relación de Residuo de Operación con SAP" ref="sap_material_contract_id" prop="sap_material_contract_id">
                      <a-select v-model="dischargeMaterialForm.sap_material_contract_id" placeholder="Seleccionar"
                                show-search
                                option-filter-prop="children"
                                :filter-option="filterOption" class="small"
                                disabled
                      >
                        <a-select-option  v-for="option in sapMaterialContracts" :key="option.jsonApi.id" :value="option.jsonApi.id">
                          Residuo: {{option.materials.name}} | SAP: {{option.sapMaterials.code}} - {{option.sapMaterials.name}}
                        </a-select-option>
                      </a-select>
                    </a-form-model-item>
                  </a-col>
                </a-row>
              </a-form-model>
            </a-row>
          </a-row>
          <div v-show="bShowOperation === false && bShowLab === false">
            <!--STEPS-->
            <a-steps :current="current">
              <a-step v-for="item in steps" :key="item.title" :title="item.title" />
            </a-steps>
            <div class="steps-content">
              <template v-if="steps[current].key === 'materials'">
                <div v-if="materialId !== null">
                  <responsive-button pill text="Reset" icon="trash" variant="danger" class="mb-2"
                                     responsive="md" @ClickEvent="resetSapMaterialSelected()"/>
                  <h6> Clave del Residuo EcoNet: {{materialId}} seleccionado</h6>
                </div>
                <materialForm :clientId="serviceClient"
                  v-else @Created="setMaterialSelected($event)" @Selected="setMaterialSelected($event)"/>
              </template>
              <template v-if="steps[current].key === 'sap_material_contracts'">
                <div v-if="Object.keys(sapMaterialContractSelected).length > 0">
                  <responsive-button pill text="Reset" icon="trash" variant="danger" class="mb-2"
                                     responsive="md" @ClickEvent="resetSapMaterialContractSelected()"/>
                  <h6 > Contrato de SAP: {{sapMaterialContractSelected.clientContracts.contract_code}} seleccionado</h6>
                  <br>
                  <h6 > Residuo de SAP: {{sapMaterialContractSelected.sapMaterials.code}}
                    - {{sapMaterialContractSelected.sapMaterials.name}} seleccionado</h6>
                </div>
                <sapMaterialForm v-else :materialId="materialId" :clientContractId="servicePlaneable"
                                 @Created="setSapMaterialContractSelected($event)"
                                 @Selected="setSapMaterialContractSelected($event)"
                />
              </template>
              <template v-if="steps[current].key === 'waste'">
                <a-form-model class="pb-4" layout="horizontal" :model="dischargeMaterialForm" :rules="dischargeMaterialRules" ref="discharge-Form">
                  <a-row align="middle" justify="center">
                    <a-col :sm="{ span: 24 }" :md="{ span:12}">
                      <a-form-model-item label="Número de Manifiesto" ref="number" prop="number">
                        <a-input class="block text-center" placeholder="Número de Manifiesto" v-model="dischargeMaterialForm.manifest_number" disabled/>
                      </a-form-model-item>
                    </a-col>
                    <a-col :sm="{ span: 24 }" :md="{ span:12}">
                      <a-form-model-item label="Ticket de Báscula" ref="weight_registry_id" prop="weight_registry_id">
                        <a-select v-model="dischargeMaterialForm.weight_registry_id" placeholder="Seleccionar" >
                          <a-select-option  v-for="option in weightRegistries" :key="option.jsonApi.id" :value="option.jsonApi.id" >
                            Ticket entrada: {{option.entry_ticket}} | Ticket salida: {{option.exit_ticket}}
                          </a-select-option>
                        </a-select>
                      </a-form-model-item>
                    </a-col>
                    <a-col span="24">
                      <a-form-model-item label="Relación de Residuo de Operación con SAP" ref="sap_material_contract_id" prop="sap_material_contract_id">
                        <a-select v-model="dischargeMaterialForm.sap_material_contract_id" placeholder="Seleccionar"
                                  show-search
                                  option-filter-prop="children"
                                  :filter-option="filterOption" class="small"
                        >
                          <a-select-option  v-for="option in sapMaterialContracts" :key="option.jsonApi.id" :value="option.jsonApi.id">
                            Residuo: {{option.materials.name}} | SAP: {{option.sapMaterials.code}} - {{option.sapMaterials.name}}
                            <!--                      Material SAP: {{option.sapMaterials.code}} - {{option.sapMaterials.name}} | Material Operaciones: {{option.materials.name}} | Contrato: {{option.clientContracts.contract_code}} | Cliente: {{option.clientContracts.clients.business_name}}-->
                          </a-select-option>
                        </a-select>
                      </a-form-model-item>
                    </a-col>
                  </a-row>
                  <hr class="bg-primary">
                  <a-row>
                    <a-col :sm="{ span: 24 }" :md="{ span:12}">
                      <a-form-model-item label="Tipo de Disposición" class="mx-1" ref="disposal_type_id" prop="disposal_type_id">
                        <a-select v-model="dischargeMaterialForm.disposal_type_id" placeholder="Seleccionar"
                                  show-search
                                  option-filter-prop="children"
                                  :filter-option="filterOption" class="small"
                        >
                          <a-select-option  v-for="option in disposalTypes" :key="option.jsonApi.id" :value="option.jsonApi.id" >
                            {{option.name}}
                          </a-select-option>
                        </a-select>
                      </a-form-model-item>
                    </a-col>
                    <a-col :sm="{ span: 24 }" :md="{ span:12}">
                      <a-form-model-item label="Tipos de Peligrosidad" class="mx-1" ref="dangerous_type_id" prop="dangerous_type_id">
                        <a-select v-model="dischargeMaterialForm.dangerous_type_id" placeholder="Seleccionar"
                                  show-search
                                  option-filter-prop="children"
                                  :filter-option="filterOption" class="small"
                        >
                          <a-select-option  v-for="option in dangerousTypes" :key="option.jsonApi.id" :value="option.jsonApi.id" >
                            {{option.name}}
                          </a-select-option>
                        </a-select>
                      </a-form-model-item>
                    </a-col>
                  </a-row>
                  <h5>Registro de Incidencias (sólo si son necesarias)</h5>
                  <hr class="bg-primary">
                  <a-row>
                    <a-col :sm="{ span: 24 }" :md="{ span:12}">
                      <a-form-model-item label="Incidencia en manifiesto" class="mx-1" ref="container_is_ok" prop="container_is_ok">
                        <a-switch default-checked v-model="dischargeMaterialForm.container_is_ok">
                    <span slot="checkedChildren">
                      <a-icon type="check" />
                      Sin Incidencia
                    </span>
                          <span slot="unCheckedChildren">
                      <a-icon type="close" />
                      Con Incidencia
                    </span>
                        </a-switch>
                      </a-form-model-item>
                    </a-col>
                    <a-col :sm="{ span: 24 }" :md="{ span:12}">
                      <a-form-model-item label="Motivo de error en registro" class="mx-1" v-show="!dischargeMaterialForm.container_is_ok">
                        <a-select v-model="dischargeMaterialForm.record_container_error" placeholder="Seleccionar"
                                  show-search
                                  option-filter-prop="children"
                                  :filter-option="filterOption" class="small"
                        >
                          <a-select-option  v-for="option in options" :key="option.id" :value="option.id" >
                            {{option.name}}
                          </a-select-option>
                        </a-select>
                      </a-form-model-item>
                    </a-col>
                  </a-row>
                  <a-row>
                    <a-col :sm="{ span: 24 }" :md="{ span: 12}">
                      <a-form-model-item label="Incidencia con el Residuo" ref="discharge_material_incident_id" prop="discharge_material_incident_id">
                        <a-select v-model="dischargeMaterialForm.discharge_material_incident_id" placeholder="Seleccionar"
                                  show-search
                                  option-filter-prop="children"
                                  :filter-option="filterOption" class="small"
                        >
                          <a-select-option  v-for="option in dischargeMaterialIncidents" :key="option.jsonApi.id" :value="option.jsonApi.id" >
                            {{option.code}} - {{option.name}}
                          </a-select-option>
                        </a-select>
                      </a-form-model-item>
                    </a-col>
                  </a-row>
                  <h5>Registro de Materiales de Manifiesto</h5>
                  <hr class="bg-primary">
                  <a-row>
                    <a-col :sm="{ span: 24 }" :md="{ span: 12}">
                      <a-form-model-item label="Tipo de Contenedor" ref="container_type_id" prop="container_type_id">
                        <a-select v-model="dischargeContainerManifestForm.container_type_id" placeholder="Seleccionar"
                                  show-search
                                  option-filter-prop="children"
                                  :filter-option="filterOption" class="small"
                        >
                          <a-select-option  v-for="option in containerTypes" :key="option.jsonApi.id" :value="option.jsonApi.id" >
                            {{option.name}}
                          </a-select-option>
                        </a-select>
                      </a-form-model-item>
                    </a-col>
                    <a-col :sm="{ span: 24 }" :md="{ span: 12}">
                      <a-form-model-item label="Número de Contenedores" ref="container_quantity" prop="container_quantity">
                        <a-input class="block" placeholder="Número de Contenedores" v-model="dischargeContainerManifestForm.container_quantity"/>
                      </a-form-model-item>
                    </a-col>
                  </a-row>
                  <a-row>
                    <a-col :sm="{ span: 24 }" :md="{ span: 12}">
                      <a-form-model-item label="Cantidad Total del Residuo" ref="quantity" prop="quantity">
                        <!--                  <a-input-number id="quantity" v-model="dischargeContainerManifestForm.quantity" :min="0" :max="10000000"/>-->
                        <a-input class="block" placeholder="Cantidad Total del Residuo" v-model="dischargeContainerManifestForm.quantity"/>
                      </a-form-model-item>
                    </a-col>
                    <a-col :sm="{ span: 24 }" :md="{ span: 12}">
                      <a-form-model-item label="Unidad de Medida" ref="u_m_s_id" prop="u_m_s_id">
                        <a-select v-model="dischargeContainerManifestForm.u_m_s_id" placeholder="Seleccionar"
                                  show-search
                                  option-filter-prop="children"
                                  :filter-option="filterOption" class="small"
                        >
                          <a-select-option  v-for="option in ums" :key="option.jsonApi.id" :value="option.sap_code" >
                            {{option.name}}
                          </a-select-option>
                        </a-select>
                      </a-form-model-item>
                    </a-col>
                  </a-row>
                  <h5>Registro de Materiales Reales</h5>
                  <hr class="bg-primary">
                  <a-row>
                    <a-col :sm="{ span: 24 }" :md="{ span: 12}">
                      <a-form-model-item label="Tipo de Contenedor" ref="container_type_id_real" prop="container_type_id_real">
                        <a-select v-model="dischargeMaterialForm.container_type_id_real" placeholder="Seleccionar"
                                  show-search
                                  option-filter-prop="children"
                                  :filter-option="filterOption" class="small"
                        >
                          <a-select-option  v-for="option in containerTypes" :key="option.jsonApi.id" :value="option.jsonApi.id" >
                            {{option.name}}
                          </a-select-option>
                        </a-select>
                      </a-form-model-item>
                    </a-col>
                    <a-col :sm="{ span: 24 }" :md="{ span: 12}">
                      <a-form-model-item label="Número de Contenedores" ref="container_quantity_real" prop="container_quantity_real">
                        <a-input class="block" placeholder="Número de Contenedores" v-model="dischargeMaterialForm.container_quantity_real"/>
                        <!--                  <a-input-number id="container_quantity_real" v-model="dischargeContainerRealForm.container_quantity" :min="0" :max="10000000"/>-->
                      </a-form-model-item>
                    </a-col>
                  </a-row>
                  <a-row class="mt-3" v-show="!load">
                    <div class="col-md-4 offset-md-4">
                      <responsive-button block variant="primary" pill size="md"
                                         :text="(dischargeMaterialForm.id === '0') ? 'Guardar' : 'Editar'" responsive="md"
                                         :icon="(dischargeMaterialForm.id === '0') ? 'archive-fill' : 'pencil-square'"
                                         @ClickEvent="OnSubmit"
                                         class="mr-2"
                      />
                    </div>
                  </a-row>
                  <my-spinner :load="load"></my-spinner>
                </a-form-model>
              </template>
            </div>
            <div class="steps-action mb-4">
              <div class="row justify-content-center">
                <b-button variant="secondary" pill v-if="current > 0" @click="prev">
                  <b-icon icon="arrow-left"/>
                </b-button>
<!--                <b-button v-if="current < steps.length - 1 && materialId !== null"-->
<!--                          variant="primary" pill  @click="next">-->
<!--                  <b-icon icon="arrow-right"/>-->
<!--                </b-button>-->
                <b-button v-if="current < steps.length - 1"
                          variant="primary" pill  @click="next">
                  <b-icon icon="arrow-right"/>
                </b-button>
              </div>
            </div>
            <!--STEPS-->
          </div>
        </b-container>
        <template v-slot:modal-footer>
          <simple-modal-footer @ClickEvent="CloseMaterialModal"/>
        </template>
      </b-modal>
    </div>
    <div class="row my-4 ml-1">
      <b-container fluid >
        <a-form-model layout="horizontal" :model="mainFilterForm">
          <a-row align="middle" justify="center">
            <a-col :sm="{ span: 24 }" :md="{ span:12}" :lg="{ span:8}" >
              <a-form-model-item label="Recibió" class="mx-1">
                <a-input placeholder="Recibió" disabled v-model="mainFilterForm.recepcionist" class="text-center"/>
              </a-form-model-item>
            </a-col>
            <a-col :sm="{ span: 24 }" :md="{ span:12}" :lg="{ span:8}">
              <a-form-model-item label="Supervisó" class="mx-1">
                <a-input placeholder="Supervisó" disabled v-model="mainFilterForm.supervisor" class="text-center"/>
              </a-form-model-item>
            </a-col>
            <a-col :sm="{ span: 24 }" :md="{ span:12}" :lg="{ span:8}" >
              <a-row>
                <a-col span="20">
                  <a-form-model-item label="Número de Manifiesto">
                    <a-select placeholder="Seleccionar" @change="handleChange">
                      <a-select-option  v-for="option in manifests" :key="option.jsonApi.id" :value="option.jsonApi.id">
                        {{option.number}}
                      </a-select-option>
                    </a-select>
                  </a-form-model-item>
                </a-col>
                <a-col span="4">
                  <br>
                  <br>
                  <responsive-button block class="mt-1" variant="bg-blue" size="sm" pill icon="arrow-repeat" text="" @ClickEvent="getManifests"
                                     responsive="md"/>
                </a-col>
              </a-row>
            </a-col>
          </a-row>
        </a-form-model>
      </b-container>
    </div>
    <div v-show="typeForm === 'inspectionMaterial'">
      <waste-service-table :tableObject="tableObject"
                           :typeForm="typeForm"
                           @CheckUpdate="CheckUpdate"
                           @Modal="OpenMaterialModal"
                           @DeleteRecord="OnDelete"
                           @Filter="getData"
      />
    </div>
    <div v-show="typeForm !== 'inspectionMaterial'">
      <div class="row" v-show="laboratoryInspectionForm.review_check_status === NO_AUTHORIZED_LABORATORY_INSPECTION || (laboratoryInspectionForm.review_check_status === undefined && mainFilterForm.lab_checks)">
<!--      <div class="row" v-show="laboratoryInspectionForm.review_check_status === NO_AUTHORIZED_LABORATORY_INSPECTION || (laboratoryInspectionForm.review_check_status === undefined && mainFilterForm.lab_checks && laboratoryInspectionForm.id === '0')">-->
        <div class="col-12">
          <h5>Autorización de Laboratorio</h5>
        </div>
        <div class="col-12">
          <hr class="bg-primary">
        </div>
        <div class="col-12">
          <b-container fluid>
          <a-form-model layout="horizontal" :model="laboratoryInspectionForm" :rules="laboratoryRules" ref="labValidation-Form">
            <a-row justify="center">
              <a-col :span="16">
                <a-col :span="24">
                  <a-form-model-item label="¿Quién revisó?" class="mx-1" ref="user_id" prop="user_id">
                    <a-select placeholder="Seleccionar" v-model="laboratoryInspectionForm.user_id">
                      <a-select-option  v-for="option in users" :key="option.jsonApi.id" :value="option.jsonApi.id" >
                        {{option.name}}
                      </a-select-option>
                    </a-select>
                  </a-form-model-item>
                </a-col>
                <a-col :span="24">
                  <a-form-model-item label="Fecha de Autorización" class="mx-1" ref="dated_at" prop="dated_at">
                    <a-date-picker format="DD/MM/YYYY" v-model="laboratoryInspectionForm.dated_at" block />
                  </a-form-model-item>
                </a-col>
                <a-col :span="24">
                  <a-form-model-item label="Comentarios" class="mx-1" ref="details" prop="details">
                    <!-- <a-textarea v-model="downloadInspectionForm.comments" placeholder="Comentarios" :rows="4" allow-clear/> -->
                    <a-textarea placeholder="Comentarios" :rows="4" allow-clear v-model="laboratoryInspectionForm.details"/>
                  </a-form-model-item>
                </a-col>
              </a-col>
              <!-- BOTONES -->
              <a-col :span="8" align="middle" class="mt-4" v-if="!LOCK_SERVICE">
                <a-row>
                  <a-form-model-item class="mt-4">
                    <b-button-group v-show="laboratoryInspectionForm.review_check_status === undefined">
<!--                      <b-button class="mx-1" variant="success" pill size="lg" v-b-tooltip.hover title="Autorizar" @click="onConfirmLaboratoryInspection">-->
                      <div v-show="laboratoryInspectionForm.temporal_review_check_status !== undefined">
                        <b-button class="mx-1" variant="success" pill size="lg" v-b-tooltip.hover title="Autorizar"
                                  v-show="laboratoryInspectionForm.temporal_review_check_status === AUTHORIZED_LABORATORY_INSPECTION"
                        >
                          <b-icon icon="check" font-scale="6"/>
                        </b-button>
                        <b-button class="mx-1" variant="danger" pill size="lg" v-b-tooltip.hover title="Cancelar"
                                  v-show="laboratoryInspectionForm.temporal_review_check_status === NO_AUTHORIZED_LABORATORY_INSPECTION"
                        >
                          <b-icon icon="x" font-scale="6"/>
                        </b-button>
                      </div>
                      <div v-show="laboratoryInspectionForm.temporal_review_check_status === undefined">
                        <b-button class="mx-1" variant="success" pill size="lg" v-b-tooltip.hover title="Autorizar" @click="onConfirmLaboratoryInspectionTemporal">
                          <b-icon icon="check" font-scale="6"/>
                        </b-button>
                        <b-button class="mx-1" variant="danger" pill size="lg" v-b-tooltip.hover title="Cancelar" @click="onCancelLaboratoryInspectionTemporal">
                          <b-icon icon="x" font-scale="6"/>
                        </b-button>
                      </div>
                    </b-button-group>
                    <b-button-group v-show="laboratoryInspectionForm.review_check_status !== undefined">
                      <div v-show="!laboratoryInspectionForm.on_restore">
                        <b-button class="mx-1" variant="success" pill size="lg" v-b-tooltip.hover title="Autorizar"
                                  v-show="laboratoryInspectionForm.review_check_status === AUTHORIZED_LABORATORY_INSPECTION"
                        >
                          <b-icon icon="check" font-scale="6"/>
                        </b-button>
                        <b-button class="mx-1" variant="danger" pill size="lg" v-b-tooltip.hover title="Cancelar"
                                  v-show="laboratoryInspectionForm.review_check_status === NO_AUTHORIZED_LABORATORY_INSPECTION"
                        >
                          <b-icon icon="x" font-scale="6"/>
                        </b-button>
                        <div v-show="laboratoryInspectionForm.temporal_review_check_status !== undefined">
                          <b-button class="mx-1" variant="success" pill size="lg" v-b-tooltip.hover title="Autorizar"
                                    v-show="laboratoryInspectionForm.temporal_review_check_status === AUTHORIZED_LABORATORY_INSPECTION"
                          >
                            <b-icon icon="check" font-scale="6"/>
                          </b-button>
                          <b-button class="mx-1" variant="danger" pill size="lg" v-b-tooltip.hover title="Cancelar"
                                    v-show="laboratoryInspectionForm.temporal_review_check_status === NO_AUTHORIZED_LABORATORY_INSPECTION"
                          >
                            <b-icon icon="x" font-scale="6"/>
                          </b-button>
                        </div>
                      </div>
                      <div v-show="laboratoryInspectionForm.on_restore">
                        <div v-show="laboratoryInspectionForm.temporal_review_check_status !== undefined">
                          <b-button class="mx-1" variant="success" pill size="lg" v-b-tooltip.hover title="Autorizar"
                                    v-show="laboratoryInspectionForm.temporal_review_check_status === AUTHORIZED_LABORATORY_INSPECTION"
                          >
                            <b-icon icon="check" font-scale="6"/>
                          </b-button>
                          <b-button class="mx-1" variant="danger" pill size="lg" v-b-tooltip.hover title="Cancelar"
                                    v-show="laboratoryInspectionForm.temporal_review_check_status === NO_AUTHORIZED_LABORATORY_INSPECTION"
                          >
                            <b-icon icon="x" font-scale="6"/>
                          </b-button>
                        </div>
                        <div v-show="laboratoryInspectionForm.temporal_review_check_status === undefined">
                          <b-button class="mx-1" variant="success" pill size="lg" v-b-tooltip.hover title="Autorizar" @click="onConfirmLaboratoryInspectionTemporal">
                            <b-icon icon="check" font-scale="6"/>
                          </b-button>
                          <b-button class="mx-1" variant="danger" pill size="lg" v-b-tooltip.hover title="Cancelar" @click="onCancelLaboratoryInspectionTemporal">
                            <b-icon icon="x" font-scale="6"/>
                          </b-button>
                        </div>
                      </div>
                    </b-button-group>
                  </a-form-model-item>
                </a-row>
                <hr class="bg-primary">
                <a-row class="pt-2">
                  <responsive-button icon="arrow-counterclockwise" text="Reestablecer"
                                     responsive="md" title="Reestablecer"
                                     block variant="primary" pill size="sm"
                                     @ClickEvent="onRestoreLabStatus"
                  />
                </a-row>
              </a-col>
            </a-row>
            <a-row class="mt-3" v-show="!load">
              <div class="col-md-4 offset-md-4" v-if="!LOCK_SERVICE">
                <responsive-button block variant="primary" pill size="md"
                                   :text="(laboratoryInspectionForm.id === '0') ? 'Guardar' : 'Editar'" responsive="md"
                                   :icon="(laboratoryInspectionForm.id === '0') ? 'archive-fill' : 'pencil-square'"
                                   @ClickEvent="OnSubmitLabValidation"
                                   class="mr-2"
                />
              </div>
            </a-row>
          </a-form-model>
        </b-container>
        </div>
      </div>
      <waste-service-table :tableObject="tableObject"
                           :typeForm="typeForm"
                           @CheckUpdate="CheckUpdate"
                           @Modal="OpenMaterialModal"
                           @DeleteRecord="OnDelete"
                           @Filter="getData"
                           v-show="((mainFilterForm.lab_checks && laboratoryInspectionForm.id !=='0' && laboratoryInspectionForm.review_check_status === AUTHORIZED_LABORATORY_INSPECTION) || (!mainFilterForm.lab_checks && laboratoryInspectionForm.id === '0'))"
      />
    </div>
  </div>
</template>

<script>
import 'moment/locale/es'
import moment from 'moment'
import { mapGetters } from 'vuex'
import api from '@/services/axios'
import { Modal, notification } from 'ant-design-vue'
import { getGeneralMixin } from '@/mixins/getGeneralMixin'
import materialForm from '@/views/geocycle/catalogs/materials/materialForm'
import { objectArray, resolvePagination } from '@/helpers/jsonApiTransformer'
import sapMaterialForm from '@/views/geocycle/catalogs/sap_materials/sapMaterialForm'
import WasteServiceTable from '@/views/geocycle/service_plannings/components/waste/wasteServiceTable'
import warehouseInputTransactionForm from './components/warehouseInputTransactionForm'
export default {
  name: 'MaterialInspectionForm',
  mixins: [getGeneralMixin],
  components: {
    // ServiceDetailModal,
    WasteServiceTable,
    materialForm,
    sapMaterialForm,
    warehouseInputTransactionForm,
  },
  props: {
    typeForm: {
      default: 'inspectionDischarge',
      type: String,
    },
  },
  data() {
    return {
      // Steps
      current: 0,
      steps: [
        {
          title: 'Residuos',
          key: 'materials',
        },
        {
          title: 'Residuos de SAP por Contrato',
          key: 'sap_material_contracts',
        },
        {
          title: 'Registro de Residuos',
          key: 'waste',
        },
      ],
      // materialSelected,
      materialId: null,
      sapMaterialContractSelected: {},
      sapMaterialContractId: null,
      // ************************************
      // ************************************
      // Variables globales
      load: false,
      sapProgrammingCode: this.$route.params.folio,
      modalShow: false,
      laboratoryModalShow: false,
      serviceDetailModalShow: false,
      dischargeMaterialId: '0',
      dischargeContainerId: '0',
      tableObject: {
        data: [],
        loading: false,
        pagination: {
          current: 1,
          pageSize: 15,
          from: 0,
          to: 0,
          total: 30,
          lastPage: 0,
        },
      },
      // Variables globales
      // ************************************
      // ************************************
      //
      // ************************************
      // ************************************
      // Tabla Tablas del Proceso
      serviceDetailModalService: {},
      // Tabla Tablas del Proceso
      // ************************************
      // ************************************
      //
      // ************************************
      // ************************************
      // Variables para el formulario
      options: [
        {
          id: 1,
          name: 'No viene el tipo de contenedor en manifiesto',
        },
        {
          id: 2,
          name: 'No viene la cantidad de contenedor en manifiesto',
        },
        {
          id: 3,
          name: 'No viene la unidad de medida en manifiesto',
        },
        {
          id: 4,
          name: 'No es claro en manifiesto',
        },
        {
          id: 5,
          name: 'Error de registro en Manifiesto',
        },
      ],
      // ************************************
      // ************************************
      // Visualización de Laboratorio
      AUTHORIZED_LABORATORY_INSPECTION: 'Autorizado',
      NO_AUTHORIZED_LABORATORY_INSPECTION: 'Rechazado',
      laboratoryInspectionForm: {
        id: '0',
        user_id: undefined,
        temporal_review_check_status: undefined,
        review_check_status: undefined,
        dated_at: moment(),
        details: undefined,
        on_restore: false,
      },
      laboratoryRules: {
        user_id: [
          {
            required: true,
            message: 'El campo de Usuario que autoriza el Laboratorio es obligatorio.',
            trigger: 'blur',
          },
        ],
        review_check_status: [
          {
            required: true,
            message: 'El campo del Estatus de autorización es obligatorio.',
            trigger: 'blur',
          },
        ],
        dated_at: [
          {
            required: true,
            message: 'El campo de la Fecha de autorización es obligatoria.',
            trigger: 'blur',
          },
        ],
        details: [
          {
            required: true,
            message: 'El campo de Detalles de la inspección de laboratorio es obligatorio.',
            trigger: 'blur',
          },
        ],
      },
      // Visualización de Laboratorio
      // ************************************
      // ************************************
      dischargeMaterialForm: {
        id: '0',
        manifest_number: undefined,
        manifest_id: undefined,
        weight_registry_id: undefined,
        sap_material_contract_id: undefined,
        container_is_ok: true,
        record_container_error: undefined,
        real_container_is_ok: undefined,
        disposal_type_id: undefined,
        dangerous_type_id: undefined,
        discharge_material_incident_id: undefined,
        authorize_check: undefined,
        warehouse_check: undefined,
        sampling_check: undefined,
        container_type_id_real: undefined,
        container_quantity_real: undefined,
      },
      dischargeContainerManifestForm: {
        discharge_material_id: undefined,
        container_type_id: undefined,
        container_quantity: undefined,
        quantity: undefined,
        u_m_s_id: undefined,
        discharge_container_type: undefined,
      },
      dischargeContainerRealForm: {
        discharge_material_id: undefined,
        container_type_id: undefined,
        container_quantity: undefined,
        quantity: undefined,
        u_m_s_id: undefined,
        discharge_container_type: undefined,
      },
      mainFilterForm: {
        supervisor: undefined,
        recepcionist: undefined,
        lab_checks: undefined,
        manifest_number: undefined,
      },
      dischargeMaterialRules: {
        weight_registry_id: [
          {
            required: true,
            message: 'El campo de Ticket de Báscula es obligatorio.',
            trigger: 'blur',
          },
        ],
        disposal_type_id: [
          {
            required: true,
            message: 'El tipo de disposición es obligatorio.',
            trigger: 'blur',
          },
        ],
        container_type_id_real: [
          {
            required: true,
            message: 'El tipo de peligrosidad es obligatorio.',
            trigger: 'blur',
          },
        ],
        container_quantity_real: [
          {
            required: true,
            message: 'El tipo de peligrosidad es obligatorio.',
            trigger: 'blur',
          },
        ],
      },
      // Variables para el formulario
      // ************************************
      // ************************************
      //
      // ************************************
      // ************************************
      // Variables para el manejo de catálogos
      manifests: [],
      weightRegistries: [],
      sapMaterialContracts: [],
      disposalTypes: [],
      dangerousTypes: [],
      dischargeMaterialIncidents: [],
      specificManifest: [],
      containerTypes: [],
      ums: [],
      users: [],
      // Variables para el manejo de catálogos
      // ************************************
      // ************************************
      //
      // ************************************
      // ************************************
      // Variables secundarias para el manejo del modal
      bShowOperation: false,
      checkOperation: 'Sin Autorización',
      bShowLab: false,
      checkLab: 'Sin Prueba',
      isDischargeClose: false,
      // Variables secundarias para el manejo del modal
      // ************************************
      // ************************************
      // VArieble para indicar el cliente asociado al servicio
      serviceClient: null,
      // ************************************
      // ************************************
      // Variable indicates that lab is affected
      labAffected: false,

      // ************************************
      // ************************************
      // Variable indicates that lab is affected
      warehouseModalShow: false,
    }
  },
  computed: {
    ...mapGetters('servicePlannings', ['LOCK_SERVICE']),
    servicePlaneable() {
      const planeable = this.$store.state.servicePlannings.currentService.service_planeable
      if (Object.prototype.hasOwnProperty.call(planeable, ['clients'])) {
        return planeable.jsonApi.id
      } else return null
    },
  },
  methods: {

    // ************************************
    // ************************************
    // Modal warehouse methods
    openWarehouseModal() {
      if (this.dischargeMaterialForm.manifest_id === undefined) {
        Modal.error({
          title: 'Error',
          content: 'Es necesario seleccionar un manifiesto para poder ejecutar la petición. Revise su selección.',
          okText: 'Ok',
          centered: true,
        })
        return 0
      }

      const me = this
      Modal.confirm({
        title: '¿Está seguro de realizar el cierre de la descarga?"',
        okText: 'Si',
        okType: 'primary',
        cancelText: 'No',
        cancelType: 'secondary',
        zIndex: 3000,
        onOk() {
          me.warehouseModalShow = true
        },
        onCancel() {
          Modal.info({
            title: 'Aviso',
            content: 'El cierre de la descarga se ha rechazado',
            okText: 'Ok',
            centered: true,
          })
        },
      })
    },
    closeWarehouseModal() {
      this.warehouseModalShow = false
    },
    // Modal warehouse methods
    // ************************************
    // ************************************

    // Steps
    next() {
      this.current++
    },
    prev() {
      this.current--
    },
    setMaterialSelected(data) {
      this.materialId = data.jsonApi.id
      this.current = 1
    },
    resetSapMaterialSelected() {
      this.materialId = null
    },
    setSapMaterialContractSelected(data) {
      // console.log(data)
      this.sapMaterialContractId = data.jsonApi.id
      this.sapMaterialContractSelected = data
      this.dischargeMaterialForm.sap_material_contract_id = data.jsonApi.id
      this.getSapMaterialContracts()
      this.current = 2
    },
    resetSapMaterialContractSelected() {
      this.sapMaterialContractId = null
      this.sapMaterialContractSelected = {}
      this.dischargeMaterialForm.sap_material_contract_id = undefined
    },
    // ************************************
    // ************************************
    // Métodos generales de búsqueda
    filterOption(input, option) {
      return (
        option.componentOptions.children[0].text.toLowerCase().indexOf(input.toLowerCase()) >= 0
      )
    },
    // Métodos generales de búsqueda
    // ************************************
    // ************************************
    //
    // ************************************
    // ************************************
    // Métodos para calcular el valor del manifiesto
    async handleChange (value) {
      this.specificResponse = []
      const params = {
        include: 'laboratoryInspections',
      }
      await this.getSpecificResourceData(params, 'manifests', value)
      const specificManifest = this.specificResponse
      this.dischargeMaterialForm.manifest_id = specificManifest.jsonApi.id
      this.dischargeMaterialForm.manifest_number = specificManifest.number
      this.labAffected = specificManifest.is_lab_close
      this.mainFilterForm.supervisor = specificManifest.supervisor.users.name
      this.mainFilterForm.recepcionist = specificManifest.recepcionist.users.name
      this.mainFilterForm.lab_checks = specificManifest.lab_checks
      this.isDischargeClose = specificManifest.is_discharge_close
      if (Object.keys(specificManifest.laboratoryInspections).length === 0 && specificManifest.laboratoryInspections.constructor === Object) {
        this.laboratoryInspectionForm.id = '0'
        this.laboratoryInspectionForm.user_id = undefined
        this.laboratoryInspectionForm.review_check_status = undefined
        this.laboratoryInspectionForm.dated_at = moment()
        this.laboratoryInspectionForm.details = undefined
      } else {
        this.laboratoryInspectionForm.id = specificManifest.laboratoryInspections.jsonApi.id
        this.laboratoryInspectionForm.user_id = `${specificManifest.laboratoryInspections.user.user_id}`
        this.laboratoryInspectionForm.review_check_status = specificManifest.laboratoryInspections.review_check_status
        this.laboratoryInspectionForm.dated_at = moment(specificManifest.laboratoryInspections.dated_at)
        this.laboratoryInspectionForm.details = specificManifest.laboratoryInspections.details
      }
      this.laboratoryInspectionForm.temporal_review_check_status = undefined
      this.laboratoryInspectionForm.on_restore = false
      this.specificResponse = []
      this.getData()
    },
    // Métodos para calcular el valor del manifiesto
    // ************************************
    // ************************************
    //
    // ************************************
    // ************************************
    // Obtención de la información para Catálogos
    async getDischargeMaterialIncidents() {
      this.generalResponse = []
      const params = {
        sort: 'name',
      }
      await this.getResourceData(params, 'dischargeMaterialIncidents')
      this.dischargeMaterialIncidents = this.generalResponse
      this.generalResponse = []
    },
    async getManifests() {
      this.generalResponse = []
      const params = {
        sort: 'number',
        'filter[servicePlannings]': `${this.sapProgrammingCode}`,
      }
      await this.getResourceData(params, 'manifests')
      this.manifests = this.generalResponse
      this.generalResponse = []
    },
    async getWeightRegistries() {
      this.generalResponse = []
      const params = {
        sort: 'code',
        'filter[sap_programming_code]': `${this.sapProgrammingCode}`,
      }
      await this.getResourceData(params, 'weightRegistries')
      this.weightRegistries = this.generalResponse
      this.generalResponse = []
    },
    async getSapMaterialContracts() {
      this.generalResponse = []
      const params = {
        // 'filter[servicePlannings]': `${this.sapProgrammingCode}`,
        include: 'clientContracts,clientContracts.clients,sapMaterials,materials',
      }
      await this.getResourceDataWithoutSort(params, 'sapMaterialContracts')
      this.sapMaterialContracts = this.generalResponse
      this.generalResponse = []
    },
    async getDisposalTypes() {
      this.generalResponse = []
      const params = {
        sort: 'name',
      }
      await this.getResourceData(params, 'disposalTypes')
      this.disposalTypes = this.generalResponse
      this.generalResponse = []
    },
    async getDangerousTypes() {
      this.generalResponse = []
      const params = {
        sort: 'name',
      }
      await this.getResourceData(params, 'dangerousTypes')
      this.dangerousTypes = this.generalResponse
      this.generalResponse = []
    },
    async getContainerTypes() {
      this.generalResponse = []
      const params = {
        sort: 'name',
      }
      await this.getResourceData(params, 'containerTypes')
      this.containerTypes = this.generalResponse
      this.generalResponse = []
    },
    async getUms() {
      this.generalResponse = []
      const params = {
        sort: 'name',
      }
      await this.getResourceData(params, 'ums')
      this.ums = this.generalResponse
      this.generalResponse = []
    },
    async getUsers() {
      this.generalResponse = []
      const params = {
        sort: 'name',
      }
      await this.getResourceData(params, 'users')
      this.users = this.generalResponse
      this.generalResponse = []
    },
    // Obtención de la información para Catálogos
    // ************************************
    // ************************************
    //
    // ************************************
    // ************************************
    // Funciones para los modales
    OpenMaterialModal(action, data = {}) {
      this.modalShow = true
      if (action === 'edit' || action === 'operationValidate' || action === 'labValidate') {
        // Assign Data
        this.dischargeMaterialForm.id = data.jsonApi.id
        this.dischargeMaterialForm.weight_registry_id = data.weightRegistries.jsonApi.id
        this.dischargeMaterialForm.sap_material_contract_id = data.sapMaterialContracts.jsonApi.id
        this.dischargeMaterialForm.disposal_type_id = data.disposalTypes.jsonApi.id
        this.dischargeMaterialForm.dangerous_type_id = data.dangerousTypes.jsonApi.id
        this.dischargeMaterialForm.container_is_ok = data.container_is_ok
        this.dischargeMaterialForm.record_container_error = data.record_container_error
        if (Object.keys(data.dischargeMaterialIncidents).length === 0 && data.dischargeMaterialIncidents.constructor === Object) {
          this.dischargeMaterialForm.discharge_material_incident_id = '0'
        } else {
          this.dischargeMaterialForm.discharge_material_incident_id = data.dischargeMaterialIncidents.jsonApi.id
        }
        // Registro de Materiales en el Manifiesto
        this.dischargeContainerManifestForm.container_type_id = data.manifestData.container_types.code
        this.dischargeContainerManifestForm.container_quantity = data.manifestData.container_quantity
        this.dischargeContainerManifestForm.quantity = data.manifestData.quantity
        this.dischargeContainerManifestForm.u_m_s_id = data.manifestData.ums.sap_code
        // Registro de Materiales en el Real
        this.dischargeContainerRealForm.container_type_id = data.realData.container_types.code
        this.dischargeContainerRealForm.container_quantity = data.realData.container_quantity
        this.dischargeMaterialForm.container_type_id_real = data.realData.container_types.code
        this.dischargeMaterialForm.container_quantity_real = data.realData.container_quantity
        // Validación para operaciones
        if (action === 'operationValidate') {
          this.bShowOperation = true
          this.bShowLab = false
          this.checkOperation = data.authorize_check
          this.checkLab = false
        } else if (action === 'labValidate') {
          this.bShowOperation = false
          this.bShowLab = true
        } else {
          this.bShowOperation = false
          this.bShowLab = false
          this.checkOperation = 'Sin Autorización'
          this.checkLab = false
        }
      }
    },
    CloseMaterialModal(data = {}) {
      this.modalShow = false
      this.ResetData()
      this.resetSapMaterialSelected()
      this.resetSapMaterialContractSelected()
      this.current = 0
    },
    OpenLaboratoryModal(data = {}) {
      this.laboratoryModalShow = true
    },
    CloseLaboratoryModal() {
      this.laboratoryModalShow = false
    },
    // Funciones para los modales
    // ************************************
    // ************************************
    //
    // ************************************
    // ************************************
    // Almacenamiento de la información
    onRestoreLabStatus() {
      this.laboratoryInspectionForm.on_restore = true
      this.laboratoryInspectionForm.temporal_review_check_status = undefined
    },
    onConfirmLaboratoryInspection() {
      this.laboratoryInspectionForm.review_check_status = this.AUTHORIZED_LABORATORY_INSPECTION
    },
    onCancelLaboratoryInspection() {
      this.laboratoryInspectionForm.review_check_status = this.NO_AUTHORIZED_LABORATORY_INSPECTION
    },
    onConfirmLaboratoryInspectionTemporal() {
      this.laboratoryInspectionForm.temporal_review_check_status = this.AUTHORIZED_LABORATORY_INSPECTION
    },
    onCancelLaboratoryInspectionTemporal() {
      this.laboratoryInspectionForm.temporal_review_check_status = this.NO_AUTHORIZED_LABORATORY_INSPECTION
    },
    OnSubmitLabValidation() {
      this.$refs['labValidation-Form'].validate(valid => {
        if (valid) {
          this.load = true
          const request = {
            id: this.laboratoryInspectionForm.id,
            manifest_id: this.dischargeMaterialForm.manifest_id,
            // review_check_status: this.laboratoryInspectionForm.review_check_status,
            review_check_status: this.laboratoryInspectionForm.temporal_review_check_status,
            dated_at: moment(this.laboratoryInspectionForm.dated_at).format('YYYY-MM-DD'),
            details: this.laboratoryInspectionForm.details,
            user_id: this.laboratoryInspectionForm.user_id,
          }
          if (this.laboratoryInspectionForm.id === '0') {
            api.post('/storeLaboratoryInspection', request)
              .then(response => {
                Modal.success({
                  title: '¡Guardado!',
                  content: 'La información de la Autorización de Laboratorios se ha almacenado exitosamente',
                  zIndex: 3000,
                })
                const responseData = response.data.data
                if (responseData.laboratoryInspection.review_check_status === this.AUTHORIZED_LABORATORY_INSPECTION) {
                  this.laboratoryInspectionForm.id = responseData.laboratoryInspection.id
                  this.laboratoryInspectionForm.review_check_status = responseData.laboratoryInspection.review_check_status
                }
                this.load = false
              }).catch(error => {
                console.log(error)
                this.load = false
              })
          } else {
            api.put('/updateLaboratoryInspection', request)
              .then(response => {
                const responseData = response.data.data
                if (responseData.laboratoryInspection.review_check_status === this.AUTHORIZED_LABORATORY_INSPECTION) {
                  this.laboratoryInspectionForm.id = responseData.laboratoryInspection.id
                  this.laboratoryInspectionForm.review_check_status = responseData.laboratoryInspection.review_check_status
                }
                Modal.success({
                  title: '¡Actualizado!',
                  content: 'La información de la Autorización de Laboratorios se ha actualizado exitosamente',
                  zIndex: 3000,
                })
                this.load = false
              }).catch(error => {
                console.log(error)
                this.load = false
              })
          }
        } else {
          return false
        }
      })
    },
    OnSubmit() {
      this.$refs['discharge-Form'].validate(valid => {
        if (valid) {
          this.load = true
          const request = {
            manifest_id: this.dischargeMaterialForm.manifest_id,
            weight_registry_id: this.dischargeMaterialForm.weight_registry_id,
            sap_material_contract_id: this.dischargeMaterialForm.sap_material_contract_id,
            container_is_ok: this.dischargeMaterialForm.container_is_ok,
            record_container_error: this.dischargeMaterialForm.record_container_error,
            real_container_is_ok: this.dischargeMaterialForm.real_container_is_ok,
            disposal_type_id: this.dischargeMaterialForm.disposal_type_id,
            dangerous_type_id: this.dischargeMaterialForm.dangerous_type_id,
            discharge_material_incident_id: this.dischargeMaterialForm.discharge_material_incident_id,
            container_type_id: this.dischargeContainerManifestForm.container_type_id,
            container_quantity: this.dischargeContainerManifestForm.container_quantity,
            quantity: this.dischargeContainerManifestForm.quantity,
            u_m_s_id: this.dischargeContainerManifestForm.u_m_s_id,
            container_type_id_real: this.dischargeMaterialForm.container_type_id_real,
            container_quantity_real: this.dischargeMaterialForm.container_quantity_real,
            discharge_material_id: this.dischargeMaterialForm.id,
          }
          if (this.dischargeMaterialForm.id === '0') {
            api.post('/specialDischargeMaterials', request)
              .then(response => {
                // const responseData = response.data
                Modal.success({
                  title: '¡Guardado!',
                  content: 'La información general de Residuos de Descarga se ha almacenado exitosamente',
                  zIndex: 3000,
                })
                this.getData()
                this.CloseMaterialModal()
                this.load = false
              }).catch(error => {
                console.log(error)
                this.load = false
              })
          } else {
            api.put('/updateDischargeMaterials', request)
              .then(response => {
                Modal.success({
                  title: '¡Actualizado!',
                  content: 'La información general de Residuos de Descarga se ha actualizado exitosamente',
                  zIndex: 3000,
                })
                this.getData()
                this.CloseMaterialModal()
                this.load = false
              }).catch(error => {
                console.log(error)
                this.load = false
              })
          }
        } else {
          return false
        }
      })
    },
    OnDelete(data = {}) {
      const me = this
      Modal.confirm({
        title: '¿Está seguro de eliminar este registro?',
        content: 'Está operación es irreversible',
        okText: 'Sí',
        cancelText: 'No',
        okType: 'danger',
        zIndex: 3000,
        onOk() {
          me.load = true
          // Delete Object
          api.delete('/deleteDischargeMaterials/' + data.jsonApi.id)
            .then(response => {
              // const responseData = response.data
              Modal.success({
                title: '¡Eliminado!',
                content: 'El registro se ha eliminado exitosamente',
                zIndex: 3000,
              })
              me.getData()
              me.load = false
            }).catch(error => {
              console.log(error)
              me.load = false
            })
        },
        onCancel() {
          notification.info({
            message: 'No se ha eliminado el registro',
          })
        },
      })
    },
    // Almacenamiento de la información
    // ************************************
    // ************************************
    closeDischargeMaterials() {
      const me = this
      if (this.dischargeMaterialForm.manifest_id === undefined) {
        Modal.error({
          title: 'Error',
          content: 'Es necesario seleccionar un manifiesto para poder ejecutar la petición. Revise su selección.',
          okText: 'Ok',
          centered: true,
        })
        return 0
      }
      me.load = true
      const request = {
        manifest_id: me.dischargeMaterialForm.manifest_id,
      }
      Modal.confirm({
        title: '¿Está seguro de realizar el cierre de la descarga?"',
        okText: 'Si',
        okType: 'primary',
        cancelText: 'No',
        cancelType: 'secondary',
        zIndex: 3000,
        onOk() {
          api.post('/closeDischargeMaterials', request)
            .then(response => {
              me.load = false
              if (response.status === 200) {
                Modal.success({
                  title: 'Concluido',
                  content: 'Se ha cerrado la descarga correctamente. Los materiales están listos para ser contabilizados.',
                  okText: 'Ok',
                  centered: true,
                })
                me.getData()
              }
            }).catch(error => {
              console.log(error)
              me.load = false
            })
        },
        onCancel() {
          Modal.info({
            title: 'Aviso',
            content: 'El cierre de la descarga se ha rechazado',
            okText: 'Ok',
            centered: true,
          })
          // notification.info({
          //   message: 'No se ha autorizado la descarga',
          // })
        },
      })
    },
    // ************************************
    // ************************************
    getData(paginationEvent = {}) {
      if (this.dischargeMaterialForm.manifest_id === undefined) {
        Modal.error({
          title: 'Error',
          content: 'Es necesario seleccionar un manifiesto para poder ejecutar la petición. Revise su selección.',
          okText: 'Ok',
          centered: true,
        })
        return 0
      }
      if (Object.keys(paginationEvent).length !== 0) {
        this.tableObject.pagination.current = paginationEvent.current
        this.tableObject.pagination.pageSize = paginationEvent.pageSize
      }
      this.tableObject.loading = true
      this.$store.dispatch('jv/get', ['dischargeMaterials', {
        params: {
          'page[number]': `${this.tableObject.pagination.current}`,
          'page[size]': `${this.tableObject.pagination.pageSize}`,
          'filter[manifests]': `${this.dischargeMaterialForm.manifest_id}`,
          include: 'sapMaterialContracts.sapMaterials,sapMaterialContracts.materials,' +
            'sapMaterialContracts.clientContracts,disposalTypes,dangerousTypes,' +
            'dischargeMaterialIncidents,weightRegistries,manifests',
        },
      }])
        .then(response => {
          const jsonApiPagination = response.jsonApi.json.meta.page
          const tableData = response
          this.tableObject.data = objectArray(tableData)
          this.tableObject.pagination = resolvePagination(jsonApiPagination)
          this.tableObject.loading = false
        })
    },
    affectLaboratory() {
      const me = this
      if (this.dischargeMaterialForm.manifest_id === undefined) {
        Modal.error({
          title: 'Error',
          content: 'Es necesario seleccionar un manifiesto para poder ejecutar la petición. Revise su selección.',
          okText: 'Ok',
          centered: true,
        })
        return 0
      }
      me.load = true
      const request = {
        manifest_id: me.dischargeMaterialForm.manifest_id,
      }
      Modal.confirm({
        title: '¿Está seguro de realizar la afectación de los residuos en Laboratorio?"',
        okText: 'Si',
        okType: 'primary',
        cancelText: 'No',
        cancelType: 'secondary',
        zIndex: 3000,
        onOk() {
          api.post('/affectLaboratory', request)
            .then(response => {
              me.load = false
              if (response.status === 200) {
                Modal.success({
                  title: 'Concluido',
                  content: 'Se ha realizado la afectación de los residuos en Laboratorio correctamente.',
                  okText: 'Ok',
                  centered: true,
                })
                me.getData()
              }
            }).catch(error => {
              console.log(error)
              me.load = false
            })
        },
        onCancel() {
          Modal.info({
            title: 'Aviso',
            content: 'La afectación de los residuos al Laboratorio se ha rechazado',
            okText: 'Ok',
            centered: true,
          })
        },
      })
    },
    ResetData() {
      // dischargeMaterialForm
      this.dischargeMaterialForm.id = '0'
      this.dischargeMaterialForm.weight_registry_id = undefined
      this.dischargeMaterialForm.sap_material_contract_id = undefined
      this.dischargeMaterialForm.container_is_ok = undefined
      this.dischargeMaterialForm.record_container_error = undefined
      this.dischargeMaterialForm.real_container_is_ok = undefined
      this.dischargeMaterialForm.disposal_type_id = undefined
      this.dischargeMaterialForm.dangerous_type_id = undefined
      this.dischargeMaterialForm.discharge_material_incident_id = undefined
      this.dischargeMaterialForm.authorize_check = undefined
      this.dischargeMaterialForm.warehouse_check = undefined
      this.dischargeMaterialForm.sampling_check = undefined
      this.dischargeMaterialForm.container_type_id_real = undefined
      this.dischargeMaterialForm.container_quantity_real = undefined
      // dischargeContainerManifestForm
      this.dischargeContainerManifestForm.discharge_material_id = undefined
      this.dischargeContainerManifestForm.container_type_id = undefined
      this.dischargeContainerManifestForm.container_quantity = undefined
      this.dischargeContainerManifestForm.quantity = undefined
      this.dischargeContainerManifestForm.u_m_s_id = undefined
      this.dischargeContainerManifestForm.discharge_container_type = undefined
      // dischargeContainerRealForm
      this.dischargeContainerRealForm.discharge_material_id = undefined
      this.dischargeContainerRealForm.container_type_id = undefined
      this.dischargeContainerRealForm.container_quantity = undefined
      this.dischargeContainerRealForm.quantity = undefined
      this.dischargeContainerRealForm.u_m_s_id = undefined
      this.dischargeContainerRealForm.discharge_container_type = undefined
      this.bShowOperation = false
      this.checkOperation = 'Sin Autorización'
      this.bShowLab = false
      this.checkLab = 'Sin Prueba'
    },
    CheckUpdateFromModal(checkType) {
      this.load = true
      let request = {}
      if (checkType === 'Discharge') {
        request = {
          discharge_material_id: this.dischargeMaterialForm.id,
          check_type: checkType,
          check: this.checkOperation,
        }
      } else {
        request = {
          discharge_material_id: this.dischargeMaterialForm.id,
          check_type: checkType,
          check: this.checkLab,
        }
      }
      // Delete Object
      api.put('/updateCheck', request)
        .then(response => {
          this.getData()
          this.CloseMaterialModal()
          this.load = false
        }).catch(error => {
          console.log(error)
          this.load = false
        })
    },
    CheckUpdate(checkValue, checkType, data = {}) {
      this.load = true
      const request = {
        discharge_material_id: data.jsonApi.id,
        check_type: checkType,
        check: checkValue,
      }
      // Delete Object
      api.put('/updateCheck', request)
        .then(response => {
          this.getData()
          this.load = false
        }).catch(error => {
          console.log(error)
          this.load = false
        })
    },
    // Funcion  para escribir el cliente asociado al servicio
    setServiceClient() {
      try {
        const planeable = this.$store.state.servicePlannings.currentService.service_planeable
        if (planeable.jsonApi.type === 'clients') {
          this.serviceClient = planeable.jsonApi.id
        } else if (planeable.jsonApi.type === 'clientContracts') {
          this.serviceClient = planeable.clients.jsonApi.id
        } else {
          this.serviceClient = null
        }
      } catch (e) {
        console.log(e)
      }
    },
  },
  mounted() {
    this.setServiceClient()
    this.getSapMaterialContracts()
    this.getWeightRegistries()
    this.getDischargeMaterialIncidents()
    this.getManifests()
    this.getDisposalTypes()
    this.getDangerousTypes()
    this.getContainerTypes()
    this.getUms()
    this.getUsers()
  },
}
</script>

<style>
.steps-content {
  margin-top: 16px;
  border: 1px dashed #e9e9e9;
  border-radius: 6px;
  background-color: #fafafa;
  min-height: 200px;
  text-align: center;
  padding-top: 80px;
}

.steps-action {
  margin-top: 24px;
}
</style>
