<template>
  <div class="my-2 px-3">
    <!-- Barra de Búsqueda -->
    <detail-catalog-bar @getData="getData"
                        :sortByOptions="sortByOptions"
                        @searchValue="searchValue = $event"
                        @sortByValue="sortByValue = $event"
                        @sortDirectionValue="sortDirectionValue = $event"
                        @perPageValue="perPageValue = $event"
                        @currentPageValue="currentPageValue = $event"
    />
    <!-- Barra de Búsqueda -->

    <!--Botonera  -->
    <div class="row justify-content-center pb-3">
      <div class="col-12 text-right">
        <b-button @click="OpenModal('store')" pill  variant="primary" >
          <b-icon-plus/> Nuevo
        </b-button>
      </div>
    </div>
    <!--Botonera  -->
    <!-- Modal -->
    <b-modal :title="`Detalle ${resourceName}`"
             ref="modal-form"
             size="lg"
    >
      <b-container fluid>
        <!-- Form with Model -->
        <a-form-model
          ref="rule-Form"
          :model="form"
          :rules="rules"
          layout="vertical"
          :wrapper-col="{ span: 24}"
        >
          <a-form-model-item ref="client_contract_id" label="Contrato de SAP del Cliente" prop="client_contract_id" >
            <a-select v-model="form.client_contract_id"
                      placeholder="Seleccionar el Contrato de SAP del Cliente"
                      show-search :filter-option="filterOption"
            >
              <a-select-option  v-for="option in client_contracts" :key="option.jsonApi.id" :value="option.jsonApi.id" >
                {{option.contract_code}}
              </a-select-option>
            </a-select>
          </a-form-model-item>
          <a-form-model-item ref="sap_material_id" label="Residuos de SAP" prop="sap_material_id" >
            <a-select v-model="form.sap_material_id"
                      placeholder="Seleccionar los Residuos de SAP"
                      show-search :filter-option="filterOption"
            >
              <a-select-option  v-for="option in sap_materials" :key="option.jsonApi.id" :value="option.jsonApi.id" >
                {{option.code}} - {{option.name}}
              </a-select-option>
            </a-select>
          </a-form-model-item>
          <a-form-model-item ref="material_id" label="Residuo de Operaciones" prop="material_id" >
            <a-select v-model="form.material_id"
                      placeholder="Seleccionar el Residuo de Operaciones"
                      show-search :filter-option="filterOption"
            >
              <a-select-option  v-for="option in materials" :key="option.jsonApi.id" :value="option.jsonApi.id" >
                {{option.name}}
              </a-select-option>
            </a-select>
          </a-form-model-item>
          <a-form-model-item ref="um_id" label="Unida de Medida" prop="um_id" >
            <a-select v-model="form.um_id"
                      placeholder="Seleccionar Unidad de Medida"
                      show-search :filter-option="filterOption"
            >
              <a-select-option  v-for="option in ums" :key="option.jsonApi.id" :value="option.jsonApi.id" >
                {{option.name}}
              </a-select-option>
            </a-select>
          </a-form-model-item>
          <a-form-model-item ref="description" label="Descripción del Residuo Pedido Cliente" prop="description" >
            <a-textarea  v-model.trim="form.sap_material_manifest_description" :rows="2"/>
          </a-form-model-item>
          <a-row :gutter="12">
            <a-col :sm="{ span: 12 }">
              <a-form-model-item ref="pos" label="Pos" prop="pos" >
                <a-input v-model.trim="form.pos" disabled class="text-center"/>
              </a-form-model-item>
            </a-col>
            <a-col :sm="{ span: 12 }">
              <a-form-model-item ref="tpos" label="TPos" prop="tpos" >
                <a-input v-model.trim="form.tpos" disabled class="text-center"/>
              </a-form-model-item>
            </a-col>
          </a-row>
          <a-form-model-item ref="unit_cost" label="Costo Unitario" prop="unit_cost" >
            <a-input-number :min="0" v-model.trim="form.unit_cost" style="width: 50%"/>
          </a-form-model-item>
          <div class="row justify-content-center" v-show="!load">
            <responsive-button variant="primary" pill size="sm" text="Guardar" responsive="md" icon="archive-fill" @ClickEvent="onSubmit"/>
            <responsive-button v-if="this.id !== '0'" variant="danger" pill size="sm" text="Eliminar" responsive="md" icon="trash" @ClickEvent="onDelete"/>
          </div>
        </a-form-model>
        <!-- Form with Model -->

        <!-- Spinner -->
        <my-spinner :load="load"/>

      </b-container>
      <template v-slot:modal-footer>
        <simple-modal-footer @ClickEvent="CloseModal"/>
      </template>
    </b-modal>
    <!-- Modal -->
    <!-- Tabla -->
    <detail-catalog-table :tableObject="tableObject" typeTable="select"
                          @Modal="OpenModal" @Filter="getData"
                          @searchInput="searchInput = $event" @Select="Selected($event)"
    >
      <div slot="details" slot-scope="record">
        <!-- <small><strong>Cliente: </strong>{{record.record.clientContracts.clients.sap_code}} - {{record.record.clientContracts.clients.name}}<br></small>
         -->

        <strong>Unidad de Medida: </strong>{{record.record.ums.name}}<br>
        <strong>Pos: </strong>{{record.record.pos}}<br>
        <strong>TPos: </strong>{{record.record.tpos}}<br>
        <strong>Costo Unitario: </strong>{{record.record.unit_cost}}<br>
      </div>
      <div slot="client_contracts" slot-scope="record">
        {{record.record.clientContracts.contract_code}}<br>
      </div>
      <div slot="sap_materials" slot-scope="record">
        <strong>{{record.record.sapMaterials.code}}</strong> - {{record.record.sapMaterials.name}}<br>
      </div>
      <div slot="materials" slot-scope="record">
        {{record.record.materials.name}}<br>
      </div>
    </detail-catalog-table>
    <!-- Tabla -->
  </div>
</template>

<script>
import { objectArray, resolvePagination } from '@/helpers/jsonApiTransformer'
import DetailCatalogTable from '../components/table'
import DetailCatalogBar from '../components/searchBar'
import { notification, Modal } from 'ant-design-vue'
import { apiRestMixin } from '@/mixins/apiRestMixin'
export default {
  name: 'sapMaterialForm',
  components: {
    DetailCatalogTable,
    DetailCatalogBar,
  },
  props: {
    materialId: {
      default: null,
      type: String,
      required: false,
    },
    clientContractId: {
      default: null,
      type: String,
      required: false,
    },
  },
  mixins: [apiRestMixin],
  data() {
    return {
      // <!-- Constantes -->
      resource: 'sapMaterialContracts',
      resourceName: 'Residuos de SAP por contrato',
      routes: [
        {
          name: 'home',
          breadcrumbName: 'Inicio',
          bIcon: 'house-fill',
        },
        {
          name: 'catalogs',
          breadcrumbName: 'Catálogos',
          aIcon: '',
        },
        {
          breadcrumbName: 'Residuos de SAP por contrato',
          aIcon: '',
        },
      ],
      searchInput: null,
      // <!-- Table -->
      tableObject: {
        data: [],
        columns: [
          {
            title: 'Contrato de SAP',
            dataIndex: '',
            key: 'client_contracts',
            class: 'small',
            scopedSlots: {
              customRender: 'clientContracts',
            },
          },
          {
            title: 'Residuo de SAP',
            dataIndex: 'sap_materials',
            key: 'sap_materials',
            class: 'small',
            scopedSlots: {
              customRender: 'sapMaterials',
            },
          },
          {
            title: 'Descripción del Residuo Pedido Cliente',
            dataIndex: 'sap_material_manifest_description',
            key: 'sap_material_manifest_description',
            class: 'text-justify small',
            scopedSlots: {
              filterDropdown: 'filterDropdown',
              filterIcon: 'filterIcon',
              customRender: 'customRender',
            },
            onFilter: (value, record) => record.sap_material_manifest_description.toLowerCase().includes(value.toLowerCase()),
            onFilterDropdownVisibleChange: (visible) => {
              if (visible) {
                setTimeout(() => {
                  this.searchInput.focus()
                }, 0)
              }
            },
          },
          {
            title: 'Residuos de Operaciones',
            dataIndex: '',
            key: 'materials',
            class: 'small',
            scopedSlots: {
              customRender: 'materials',
            },
          },
          {
            title: 'Detalles',
            dataIndex: 'detail',
            key: 'detail',
            class: 'small',
            scopedSlots: {
              customRender: 'myCustomColumns',
            },
          },
          {
            title: 'Acción',
            dataIndex: '',
            key: 'x',
            fixed: 'right',
            class: 'text-center small',
            scopedSlots: {
              customRender: 'action',
            },
          }],
        loading: false,
        pagination: {
          current: 1,
          pageSize: 15,
          from: 0,
          to: 0,
          total: 30,
          lastPage: 0,
        },
      },
      // <!-- Table -->
      // <!-- Barra de Control -->
      searchValue: '',
      sortByValue: '',
      sortByDefaultValue: 'clientContract',
      sortDirectionValue: '',
      sortByOptions: [
        { value: '', text: 'Sin ordenamiento válido', disabled: false },
      ],
      perPageValue: 15,
      currentPageValue: 1,
      // <!-- Barra de Control -->
      // <!-- Constantes -->
      // <!-- Form with Model -->
      id: '0',
      form: {
        sap_material_manifest_description: '',
        pos: 'NO INFO',
        tpos: 'NO INFO',
        unit_cost: 0,
        client_contract_id: undefined,
        sap_material_id: undefined,
        material_id: undefined,
        um_id: undefined,
      },
      // Relaciones
      relationships: {
        clientContracts: {
          data: { id: 0, type: 'clientContracts' },
        },
        sapMaterials: {
          data: { id: 0, type: 'sapMaterials' },
        },
        materials: {
          data: { id: 0, type: 'materials' },
        },
        ums: {
          data: { id: 0, type: 'ums' },
        },
      },
      // arrays selects
      client_contracts: [],
      sap_materials: [],
      materials: [],
      ums: [],
      // Reglas de validación
      rules: {
        client_contract_id: [
          { required: true, message: 'El campo Tipo de cliente es obligatorio.', trigger: 'blur' },
        ],
        sap_material_id: [
          { required: true, message: 'El campo Centro de Trabajo es obligatorio.', trigger: 'blur' },
        ],
        material_id: [
          { required: true, message: 'El campo Residuos de Operaciones es obligatorio.', trigger: 'blur' },
        ],
        um_id: [
          { required: true, message: 'El campo Unida de Medida es obligatorio.', trigger: 'blur' },
        ],
        sap_material_manifest_description: [
          { required: true, message: 'El campo Descripción es obligatorio.', trigger: 'blur' },
          // { min: 3, max: 400, message: 'El campo Nombre debe contener entre 3 y 400 caracteres.', trigger: 'blur' },
        ],
        pos: [
          { required: true, message: 'El campo Pos es obligatorio.', trigger: 'blur' },
          // { min: 3, max: 400, message: 'El campo Nombre debe contener entre 3 y 400 caracteres.', trigger: 'blur' },
        ],
        tpos: [
          { required: true, message: 'El campo Tpos es obligatorio.', trigger: 'blur' },
          // { min: 3, max: 400, message: 'El campo Nombre debe contener entre 3 y 400 caracteres.', trigger: 'blur' },
        ],
        unit_cost: [
          { required: true, message: 'El campo Costo Unitario es obligatorio.', trigger: 'blur' },
          // { min: 3, max: 400, message: 'El campo Nombre debe contener entre 3 y 400 caracteres.', trigger: 'blur' },
        ],
      },
      // <!-- Form with Model -->
      load: false,
    }
  },
  computed: {
    sortDirection() {
      if (this.sortDirectionValue === '') {
        return ''
      } else if (this.sortDirectionValue === '-') {
        return '-'
      } else {
        return ''
      }
    },
    sortValue() {
      if (this.sortByValue === '') {
        return this.sortByDefaultValue
      } else {
        return this.sortByValue
      }
    },
  },
  methods: {
    Selected(data) {
      this.$emit('Selected', data)
    },
    filterOption(input, option) {
      return (
        option.componentOptions.children[0].text.toLowerCase().indexOf(input.toLowerCase()) >= 0
      )
    },
    // <!-- Get,Paginate,Search & Filter Data  -->
    getData(paginationEvent = {}) {
      if (Object.keys(paginationEvent).length !== 0) {
        this.tableObject.pagination.current = paginationEvent.current
        this.tableObject.pagination.pageSize = paginationEvent.pageSize
      }
      const params = {
        'page[number]': (Object.keys(paginationEvent).length !== 0) ? `${this.tableObject.pagination.current}` : `${this.currentPageValue}`,
        'page[size]': `${this.perPageValue}`,
        include: 'clientContracts,clientContracts.clients,sapMaterials,materials,ums',
      }

      if (this.searchValue !== '') {
        params['filter[global]'] = `${this.searchValue}`
      }
      if (this.clientContractId != null) {
        params['filter[clientContracts]'] = `${this.clientContractId}`
      }

      this.tableObject.loading = true
      this.$store.dispatch('jv/get', [this.resource, {
        params: params,
      }])
        .then(response => {
          this.tableObject.loading = false
          const jsonApiPagination = response.jsonApi.json.meta.page
          const tableData = response
          this.tableObject.data = objectArray(tableData)
          this.tableObject.pagination = resolvePagination(jsonApiPagination)
          this.tableObject.loading = false
        })
        .catch(() => {
          this.tableObject.loading = false
        })
    },
    // <!-- Get,Paginate,Search & Filter Data  -->
    // <!-- Get array selects data  -->
    getClient_contracts() {
      this.$store.dispatch('jv/get', ['clientContracts', {
        params: {
          sort: 'contract_code',
        },
      }])
        .then(response => {
          const tableData = response
          this.client_contracts = objectArray(tableData)
        })
    },
    getSap_materials() {
      this.$store.dispatch('jv/get', ['sapMaterials', {
        params: {
          sort: 'name',
        },
      }])
        .then(response => {
          const tableData = response
          this.sap_materials = objectArray(tableData)
        })
    },
    getMaterials() {
      this.$store.dispatch('jv/get', ['materials', {
        params: {
          sort: 'name',
        },
      }])
        .then(response => {
          const tableData = response
          this.materials = objectArray(tableData)
        })
    },
    getUMS() {
      this.$store.dispatch('jv/get', ['ums', {
        params: {
          sort: 'name',
        },
      }])
        .then(response => {
          const tableData = response
          this.ums = objectArray(tableData)
        })
    },
    // <!-- Get array selects data  -->
    // <!-- Open & Close Modal Logic  -->
    OpenModal(action, data = {}) {
      this.$refs['modal-form'].show()
      this.getClient_contracts()
      this.getSap_materials()
      this.getMaterials()
      this.getUMS()
      if (Object.keys(data).length === 0) {
        //  Store case
        this.id = '0'
        this.form.sap_material_id = undefined
        this.form.um_id = undefined
        this.form.sap_material_manifest_description = ''
        this.form.pos = 'NO INFO'
        this.form.tpos = 'NO INFO'
        this.form.unit_cost = 0
        if (this.clientContractId != null) {
          this.form.client_contract_id = this.clientContractId
        } else this.form.client_contract_id = undefined
        if (this.materialId != null) {
          this.form.material_id = this.materialId
        } else this.form.material_id = undefined
      } else {
        // Update case
        this.id = data.jsonApi.id
        this.form.client_contract_id = data.clientContracts.jsonApi.id
        this.form.sap_material_id = data.sapMaterials.jsonApi.id
        this.form.material_id = data.materials.jsonApi.id
        this.form.um_id = data.ums.jsonApi.id
        this.form.sap_material_manifest_description = data.sap_material_manifest_description
        this.form.pos = data.pos
        this.form.tpos = data.tpos
        this.form.unit_cost = data.unit_cost
      }
    },
    CloseModal() {
      this.$refs['modal-form'].hide()
      this.id = '0'
      this.resetForm()
    },
    // <!-- Open & Close Modal Logic  -->
    // <!-- Store Logic  -->
    onSubmit() {
      this.$refs['rule-Form'].validate(async (valid) => {
        if (valid) {
          let action = 'post'
          const relationships = {
            clientContracts: {
              data: { id: this.form.client_contract_id, type: 'clientContracts' },
            },
            sapMaterials: {
              data: { id: this.form.sap_material_id, type: 'sapMaterials' },
            },
            materials: {
              data: { id: this.form.material_id, type: 'materials' },
            },
            ums: {
              data: { id: this.form.um_id, type: 'ums' },
            },
          }
          const formObject = {
            sap_material_manifest_description: this.form.sap_material_manifest_description,
            pos: this.form.pos,
            tpos: this.form.tpos,
            unit_cost: this.form.unit_cost,
            jsonApi: {
              type: this.resource,
              relationships: relationships,
            },
          }

          if (this.id !== '0') {
            action = 'patch'
            // Update Object
            formObject.jsonApi.id = this.id
            // Update Object
          }
          // Envio del Objecto
          this.load = true
          await this.setJsonApiResource(formObject, action, {}, (response) => {
            this.getData()
            this.CloseModal()
            notification.success({
              message: 'Registro guardado exitosamente',
            })
            this.$emit('Created', response)
          })
          this.load = false
          // Envio del Objecto
        } else {
          // Fallo de validación
          return false
        }
      })
    },
    onDelete() {
      const me = this
      Modal.confirm({
        title: '¿Esta seguro de eliminar este registro?',
        content: 'Esta operación es irreversible',
        okText: 'Sí',
        okType: 'danger',
        cancelText: 'No',
        zIndex: 3000,
        onOk() {
          // Delete Object
          const deleteObject = {
            jsonApi: {
              type: me.resource,
              id: me.id,
            },
          }
          // Delete Object
          me.$store.dispatch('jv/delete', [deleteObject, {}])
            .then((data) => {
              notification.success({
                message: 'Registro eliminado exitosamente',
              })
              me.getData()
              me.load = false
              me.CloseModal()
            })
            .catch((errors) => {
              console.log(errors)
              this.load = false
            })
        },
        onCancel() {
          notification.info({
            message: 'No se ha eliminado el registro',
          })
        },
      })
    },
    // <!-- Store Logic  -->
    resetForm() {
      this.$refs['rule-Form'].resetFields()
    },
  },
  mounted() {
    this.getData()
  },
}
</script>

<style scoped>
.custom-filter-dropdown {
  padding: 8px;
  border-radius: 4px;
  background: #fff;
  box-shadow: 0 2px 8px rgba(0, 0, 0, .15);
}
.highlight {
  background-color: rgb(255, 192, 105);
  padding: 0px;
}
</style>
