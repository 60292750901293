<template>
  <a-table
    :scroll="{ x: 1300}"
    size="small"
    bordered
    :dataSource="tableObject.data"
    :columns="columns"
    :loading="tableObject.loading"
    :row-key="record => record.code"
    :pagination="tableObject.pagination"
    @change="GetCarriers"
  >
    <!-- Columnas Personalizadas -->
    <div slot="all" slot-scope="data">
      <strong>Conductor: </strong>{{data.drivers.name}}
      <br>
      <strong>Placa: </strong>{{data.trucks.tag}}
      <!-- SEMARNAT y SCT de Transporte-->
<!--      <template v-if="data.trucks.carrierCompanies.sct_record">-->
<!--        <br>-->
<!--        <strong>Registro SCT: </strong>{{data.trucks.carrierCompanies.sct_record}}-->
<!--      </template>-->
<!--      <template v-if="data.trucks.carrierCompanies.environmental_record">-->
<!--        <br>-->
<!--        <strong>Autorización SEMARNAT: </strong>{{data.trucks.carrierCompanies.environmental_record}}-->
<!--      </template>-->
      <!-- SEMARNAT y SCT de Transporte-->
      <!-- SEMARNAT y SCT del Service3PlanningsCarriers-->
      <br>
      <strong>Registro SCT: </strong>{{data.sct_record}}
      <br>
      <strong>Autorización SEMARNAT: </strong>{{data.environmental_record}}
      <!-- SEMARNAT y SCT del Service3PlanningsCarriers-->
      <br>
      <strong>Tipo Logístico de Transporte: </strong> {{data.trucks.logisticsShipmentTypes.name}}
      <br>
      <strong>Gestión Especial de Transporte: </strong> {{data.transportation_management}}
      <br>
      <strong>Acoplados: </strong>{{data.trucks.logisticsShipmentTypes.trailers}}
      <template v-if="data.coupled_tag !== null && data.coupled_tag.length > 0">
        <br>
        <ul>
          <li v-for="tag in data.coupled_tag" :key="tag">{{tag}}</li>
        </ul>
      </template>
    </div>
    <div slot="in" slot-scope="data">
      <template v-if="Object.keys(data.weightRegistries).length > 0">
        <h6 class="text-secondary"><strong>Ticket: </strong>{{data.weightRegistries.entry_ticket}}</h6>
        <h6 class="text-secondary"><strong>Peso: </strong>{{data.weightRegistries.entry_weight}}</h6>
        <h6 class="text-secondary"><strong>Fecha: </strong>{{data.weightRegistries.entry_dated_at}}</h6>
        <h6 class="text-secondary"><strong>Hora: </strong>{{data.weightRegistries.entry_hour}}</h6>
      </template>
      <span v-else class="bg-warning py-2 px-2 rounded text-white font-weight-bold">Sin pesaje de entrada</span>
    </div>
    <div slot="output" slot-scope="data">
      <template v-if="Object.keys(data.weightRegistries).length > 0 && data.weightRegistries.exit_weight !== 0">
        <h6 class="text-secondary"><strong>Ticket: </strong>{{data.weightRegistries.exit_ticket}}</h6>
        <h6 class="text-secondary"><strong>Peso: </strong>{{data.weightRegistries.exit_weight}}</h6>
        <h6 class="text-secondary"><strong>Fecha: </strong>{{data.weightRegistries.exit_dated_at}}</h6>
        <h6 class="text-secondary"><strong>Hora: </strong>{{data.weightRegistries.exit_hour}}</h6>
      </template>
      <span v-else class="bg-warning py-2 px-2 rounded text-white font-weight-bold">Sin pesaje de salida</span>
    </div>
    <div slot="checkList" slot-scope="data">
      <template v-if="data.jsonApi.meta.checkCodes.length > 0">
        <span v-show="data.jsonApi.meta.checkCodes.includes('Check_0000000001')">
          Vigilancia <b-icon icon="check-circle" variant="primary" font-scale="1.5"/>
        </span>
        <span v-show="data.jsonApi.meta.checkCodes.includes('Check_0000000002')">
          Laboratorio <b-icon icon="check-circle" variant="primary" font-scale="1.5"/>
        </span>
        <span v-show="data.jsonApi.meta.checkCodes.includes('Check_0000000003')">
          Descarga <b-icon icon="check-circle" variant="primary" font-scale="1.5"/>
        </span>
        <span v-show="data.jsonApi.meta.checkCodes.includes('Check_0000000004')">
          Descarga Segura <b-icon icon="check-circle" variant="primary" font-scale="1.5"/>
        </span>
      </template>
      <span v-else class="bg-warning py-2 px-2 rounded text-white font-weight-bold">
        No se ha realizado ningún Check List
      </span>
    </div>

    <div slot="action" slot-scope="data">
      <b-button-group vertical>
        <template v-if="view.includes('crud')">
          <responsive-button v-if="!LOCK_SERVICE" variant="secondary" class="mb-1"
                             text="Editar" icon="pencil-square" pill size="sm" responsive="md"
                             @ClickEvent="OpenModal(data)"
          />
          <responsive-button v-if="!LOCK_SERVICE" variant="danger"
                             text="Eliminar" icon="trash" pill size="sm" responsive="md"
                             @ClickEvent="Delete(data)"
          />
        </template>
        <responsive-button v-if="view.includes('select')"
                           text="Seleccionar" icon="hand-index-thumb" class="mb-1"
                           pill variant="warning" size="sm" responsive="md"
                           @ClickEvent="Select(data)"
        />
        <responsive-button v-if="view.includes('weighing')"
                           pill  text="Realizar pesaje" icon="calculator"
                           class="mb-1 bg-real-blue"  responsive="md" size="sm"
                           :to="{name:'reception_service_plannings_weighing',params:{carrierID:data.jsonApi.id}}"/>
        <responsive-button v-if="view.includes('print')"
                           text="Impresión de Reporte" icon="printer-fill"
                           pill variant="secondary" size="sm" responsive="md"
                           to="/"/>
      </b-button-group>
    </div>
    <!-- Columnas Personalizadas -->
  </a-table>
</template>

<script>
import { getGeneralMixin } from '@/mixins/getGeneralMixin'
import { mapGetters } from 'vuex'
export default {
  name: 'servicePlanningCarrierTable',
  props: {
    serviceId: {
      type: [String],
      required: true,
    },
    excessPermanence: {
      type: [Object],
      required: false,
      default: () => {
        return {
          value: 0,
        }
      },
    },
    tableObject: {
      type: [Object],
      required: true,
    },
    view: {
      default: () => {
        return ['crud']
      },
      type: [Array],
      required: false,
    },
  },
  mixins: [getGeneralMixin],
  data() {
    return {
      columns: [
        {
          title: 'Descripción',
          dataIndex: '',
          key: 'all',
          class: 'small',
          width: '200px',
          scopedSlots: {
            customRender: 'all',
          },
        },
        {
          title: 'Pesajes',
          align: 'center',
          width: '150px',
          children: [
            {
              title: 'Entrada',
              key: 'in',
              align: 'center',
              width: '75px',
              class: 'small',
              scopedSlots: {
                customRender: 'in',
              },
            },
            {
              title: 'Salida',
              dataIndex: '',
              key: 'output',
              align: 'center',
              width: '75px',
              class: 'small',
              scopedSlots: {
                customRender: 'output',
              },
            },
          ],
        },
        {
          title: 'CheckList',
          dataIndex: '',
          key: 'checklist',
          align: 'center',
          class: 'small',
          width: '100px',
          scopedSlots: {
            customRender: 'checkList',
          },
        },
        {
          title: 'Acción',
          dataIndex: '',
          fixed: 'right',
          class: 'text-center',
          width: '100px',
          key: 'x',
          scopedSlots: {
            customRender: 'action',
          },
        }],
    }
  },
  computed: {
    ...mapGetters('servicePlannings', ['LOCK_SERVICE']),
  },
  methods: {
    hasCheck(checkListForms) {
      const checks = []
      const checkListFormsArray = Object.entries(checkListForms)
      checkListFormsArray.forEach((key, value) => {
        checks.push(key[1].checks)
      })
      const codes = checks.map(check => {
        return check.code
      })
      return codes
    },
    async GetCarriers(paginationEvent = {}) {
      let params = {}
      if (!this.excessPermanence.value) {
        params = {
          'page[number]': (Object.keys(paginationEvent).length !== 0) ? `${this.tableObject.pagination.current}` : `${this.tableObject.currentPageValue}`,
          'page[size]': `${this.tableObject.perPageValue}`,
          'filter[service]': this.serviceId,
          include: 'drivers,trucks.logisticsShipmentTypes,trucks.carrierCompanies,weightRegistries,checkListForms.checks',
        }
      } else {
        params = {
          'page[number]': (Object.keys(paginationEvent).length !== 0) ? `${this.tableObject.pagination.current}` : `${this.tableObject.currentPageValue}`,
          'page[size]': `${this.tableObject.perPageValue}`,
          'filter[excessPermanence]': this.excessPermanence.value,
          'filter[service]': this.serviceId,
          include: 'drivers,trucks.logisticsShipmentTypes,trucks.carrierCompanies,weightRegistries,checkListForms.checks',
        }
      }

      await this.getGeneralAntTable(paginationEvent, 'servicePlanningsCarriers', params, this.tableObject)
    },
    OpenModal(data = {}) {
      this.$emit('EditModal', data)
    },
    Delete(data = {}) {
      this.$emit('DeleteCarrier', data)
    },
    Select(data) {
      this.$emit('SelectCarrier', data)
    },
  },
  mounted() {
    this.GetCarriers()
  },
}
</script>

<style scoped>

</style>
