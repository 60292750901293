<template>
<div>
  <!--FORMULARIO-->
  <a-form-model :model="auxForm" :rules="formRules" ref="transactionForm"
      layout="vertical" :wrapper-col="{ span: 24}">
    <!--SELECT -Almacén -->
    <a-form-model-item :label="mainWarehouseLabel" ref="warehouse_id" prop="warehouse_id" >
        <a-select v-model="auxForm.warehouse_id" :disabled="lock" @change="ChangeMainWarehouse($event)"
                    :placeholder="`Seleccionar ${mainWarehouseLabel}`"
                    show-search :filter-option="filterOption">
            <a-select-option  v-for="option in warehouses" :key="option.id" :value="option.id" >
              {{option.name}}
            </a-select-option>
        </a-select>
    </a-form-model-item>
    <!--SELECT -Almacén -->
    <hr class="bg-primary">
    <template v-if="type === 'Traspaso'">
      <!--SELECT -Aux Almacén -->
      <a-form-model-item label="Almacén de Destino"
                         ref="auxWarehouse_id" prop="auxWarehouse_id" >
        <a-select v-model="auxForm.auxWarehouse_id" allowClear :disabled="lock"
                  placeholder="Seleccionar Almacén de Destino"
                  show-search :filter-option="filterOption">
          <a-select-option  v-for="option in warehouses" :key="option.id" :value="option.id" >
            {{option.name}}
          </a-select-option>
        </a-select>
      </a-form-model-item>
      <hr class="bg-primary">
    </template>
    <a-row align="middle" justify="center">
      <a-col :md="{ span: 24 }" :lg="{ span:8}">
        <a-form-model-item label="Tipo de Transacción" ref="transactionable_type"  prop="transactionable_type" >
          <a-radio-group v-model="auxForm.transactionable_type" :disabled="lock"
                         @change="specialCombos($event)"
                         default-value="App\Models\User" button-style="solid">
            <a-radio-button value="App\Models\User">Interna</a-radio-button>
            <a-radio-button value="App\Models\WorkCenter">Externa</a-radio-button>
          </a-radio-group>
        </a-form-model-item>
      </a-col>
      <a-col :md="{ span: 24 }" :lg="{ span:16}">
        <a-form-model-item label="Fuente de Transacción " ref="transactionable_id" prop="transactionable_id" >
          <a-select v-model="auxForm.transactionable_id" :disabled="lock"
                    :placeholder="transactionableLabel"
                    show-search :filter-option="filterOption">
            <a-select-option  v-for="option in transactionables" :key="option.id" :value="option.id" >
              {{option.name}}
            </a-select-option>
          </a-select>
        </a-form-model-item>
      </a-col>
    </a-row>
    <a-row align="middle" justify="center">
      <a-col :md="{ span: 24 }" :lg="{ span:11}">
        <a-form-model-item label="Turno" ref="operation_shift_id" prop="operation_shift_id" >
          <a-select v-model="auxForm.operation_shift_id" :disabled="lock"
                    placeholder="Seleccionar Turno"
                    show-search :filter-option="filterOption">
            <a-select-option  v-for="option in operationShifts" :key="option.id" :value="option.id" >
              {{ option.name }}
              ({{ moment(option.initial_time).format('HH:mm') }} -
              {{ moment(option.final_time).format('HH:mm') }} )
            </a-select-option>
          </a-select>
        </a-form-model-item>
      </a-col>
      <a-col :md="{ span: 24 }" :lg="{ span:12, offset:1}">
        <a-form-model-item label="Área" ref="operation_area_id" prop="operation_area_id" >
          <a-select v-model="auxForm.operation_area_id" allowClear :disabled="lock"
                    placeholder="Seleccionar Área"
                    show-search :filter-option="filterOption">
            <a-select-option  v-for="option in operationAreas" :key="option.id" :value="option.id" >
              {{option.name}}
            </a-select-option>
          </a-select>
        </a-form-model-item>
      </a-col>
    </a-row>
    <a-row align="middle" justify="center">
      <a-col :md="{ span: 24 }" :lg="{ span:10}">
        <a-form-model-item  label="Fecha y Hora de Transacción" ref="dated_at" prop="dated_at" >
          <a-date-picker  v-model="auxForm.dated_at" allowClear showTime :disabled="lock"
                          style="width: 100%" placeholder="Fecha y Hora"
                          format="DD/MM/YYYY HH:mm" size="large" valueFormat="YYYY/MM/DD HH:mm"/>
        </a-form-model-item>
      </a-col>
      <a-col :md="{ span: 24 }" :lg="{ span:13, offset:1}">
        <a-form-model-item label="Responsable" ref="user_id" prop="user_id" >
          <a-select v-model="auxForm.user_id" :disabled="lock"
                    placeholder="Seleccionar Responsable"
                    show-search :filter-option="filterOption">
            <a-select-option  v-for="option in users" :key="option.id" :value="option.id" >
              {{option.name}}
            </a-select-option>
          </a-select>
        </a-form-model-item>
      </a-col>
    </a-row>

    <a-form-model-item label="Referencia" ref="reference"  prop="reference" >
        <a-textarea v-model.trim="auxForm.reference" :disabled="lock"
                    :auto-size="{ minRows: 1, maxRows: 5 }"/>
    </a-form-model-item>

    <a-form-model-item label="Notas Especiales" ref="comments"  prop="comments" >
      <a-textarea v-model.trim="auxForm.comments" :disabled="lock"
                  :auto-size="{ minRows: 2 }"/>
    </a-form-model-item>

    <a-row>
      <a-col span="8" offset="8">
        <b-button
          pill
          block
          v-if="!lock"
          @click="inputSubmit"
          variant="primary"
        >
          <div
            class="d-none d-md-block"
            v-b-tooltip.hover title="Guardar"
          >
            <b-icon icon="lock-fill"/> Guardar
          </div>
          <div class="d-md-none" v-b-tooltip.hover title="Guardar">
            <b-icon icon="lock-fill"/>
          </div>
        </b-button>
      </a-col>
    </a-row>
  </a-form-model>
  <slot></slot>
</div>
</template>

<script>
import api from '@/services/axios'
import { Modal } from 'ant-design-vue'
import { mapActions, mapState } from 'vuex'
import { fractalMixin } from '@/mixins/fractalMixin'

export default {
  name: 'warehouseInputTransactionForm',
  mixins: [fractalMixin],
  props: {
    type: {
      required: true,
      type: String,
      validator: function (value) {
        // El valor debe coincidir con una de estas cadenas de texto
        return ['Entrada', 'Salida', 'Traspaso'].indexOf(value) !== -1
      },
    },
    workCenter: {
      required: false,
      type: String,
    },
    manifestId: {
      required: false,
      type: String,
    },
  },
  computed: {
    ...mapState('warehouse', ['form']),
    auxForm: {
      get() {
        return this.form
      },
      set(value) {
        this.UPDATE_FORM(value)
      },
    },
    transactionableLabel() {
      if (this.auxForm.transactionable_type === 'App\\Models\\User') {
        return 'Usuario'
      } else return 'Centro de Trabajo (Planta)'
    },
    mainWarehouseLabel() {
      if (this.type === 'Entrada') {
        return 'Almacén de Entrada'
      } else return 'Almacén de Salida'
    },
  },
  data() {
    return {
      formRules: {
        warehouse_transaction_id: [{ required: true, message: 'El campo Tipo de Transacción es obligatorio.', trigger: 'blur' }],
        warehouse_id: [{ required: true, message: 'El campo Almacén es obligatorio.', trigger: 'blur' }],
        user_id: [{ required: true, message: 'El campo Responsable es obligatorio.', trigger: 'blur' }],
        transactionable_id: [{ required: true, message: 'El campo Fuente de Transacción es obligatorio.', trigger: 'blur' }],
        operation_shift_id: [{ required: true, message: 'El campo Turno es obligatorio.', trigger: 'blur' }],
        dated_at: [{ required: true, message: 'El campo Fecha y Hora es obligatorio.', trigger: 'change' }],
        transactionable_type: [{ required: true, message: 'El campo Tipo de Transacción es obligatorio.', trigger: 'blur' }],
      },
      warehouses: [],
      users: [],
      operationShifts: [],
      operationAreas: [],
      transactionables: [],
      lock: false,
    }
  },
  watch: {
    async workCenter(newValue) {
      await this.Combos()
    },
  },
  methods: {
    ...mapActions('warehouse', ['UPDATE_FORM', 'RESET_FORMS', 'RESET_CARTS']),
    async Combos () {
      this.warehouses = await this.GetResource('/warehouses', {
        'filter[workCenters]': this.workCenter,
        'filter[type]': 'Operaciones',
        sortBy: 'name',
      })

      this.operationShifts = await this.GetResource('/operationShifts', {
        'filter[workCenters]': this.workCenter,
        sortBy: 'name',
      })

      this.operationAreas = await this.GetResource('/operationAreas', {
        sortBy: 'name',
      })

      this.users = await this.GetResource('/users', {
        // 'filter[workCenters]': this.workCenter,
        sortBy: 'name',
      })
    },
    async specialCombos(e) {
      if (e.target.value === 'App\\Models\\User') {
        this.transactionables = await this.GetResource('/users', {
          'filter[workCenters]': this.workCenter,
          sortBy: 'name',
        })
      } else {
        this.transactionables = await this.GetResource('/workCenters', {
          sortBy: 'name',
        })
      }
    },
    inputSubmit() {
      this.loading = true
      api.post('/affectOperationWarehouse', {
        form: this.form,
        manifest_id: this.manifestId,
      }).then(responseWarehouse => {
        console.log(responseWarehouse)
        if (responseWarehouse.status === 200 || responseWarehouse.status === 201) {
          const request = {
            manifest_id: this.manifestId,
          }
          api.post('/closeDischargeMaterials', request)
            .then(response => {
              console.log(response)
              if (response.status === 200 || response.status === 201) {
                this.$emit('getData')

                this.loading = false
                this.$emit('closeWarehouseModal')

                Modal.success({
                  title: 'Transacción exitosa',
                  content: (
                    <div class="row my-2 text-center">
                      <p class="my-4">Se ha realizado la operación exitosamente de click a continuación para administrar los residuos</p>
                      <b-button
                        pill block
                        variant="primary"
                        to="/operaciones/almacenes/ubicacciones"
                      >
                        <b-icon icon="archive"></b-icon> Administrar Ubicaciones
                      </b-button>
                    </div>
                  ),
                })
                this.RESET_FORMS()
              }
            }).catch(error => {
              console.log(error)
            })
        }
      }).catch(error => {
        console.log(error)
      })
      // this.AxiosPost('/affectOperationWarehouse', {
      //   form: this.form,
      //   manifest_id: this.manifestId,
      // }, () => {
      //   const request = {
      //     manifest_id: this.manifestId,
      //   }
      //   api.post('/closeDischargeMaterials', request)
      //     .then(response => {
      //       if (response.status === 200) {
      //         this.$emit('getData')
      //
      //         this.loading = false
      //         this.$emit('closeWarehouseModal')
      //
      //         Modal.success({
      //           title: 'Transacción exitosa',
      //           content: (
      //             <div class="row my-2 text-center">
      //               <p class="my-4">Se ha realizado la operación exitosamente de click a continuación para administrar los residuos</p>
      //               <b-button
      //                 pill block
      //                 variant="primary"
      //                 to="/operaciones/almacenes/ubicacciones"
      //               >
      //                 <b-icon icon="archive"></b-icon> Administrar Ubicaciones
      //               </b-button>
      //             </div>
      //           ),
      //         })
      //         this.RESET_FORMS()
      //       }
      //     }).catch(error => {
      //       console.log(error)
      //     })
      // }, () => { this.loading = false })
    },
    Submit() {
      this.$refs.transactionForm.validate(async(valid) => {
        if (valid) {
          this.$emit('validate', true)
          this.lock = true
        } else return false
      })
    },
    Unlock() {
      this.$emit('validate', false)
      this.lock = false
    },
    ChangeMainWarehouse(e) {
      this.$emit('onChangeMainWarehouse', e)
    },
  },
  async mounted() {
    if (this.type === 'Traspaso') {
      this.formRules.auxWarehouse_id = [{ required: true, message: 'El campo Almacén de destino es obligatorio.', trigger: 'blur' }]
    }
    await this.Combos()
    this.transactionables = this.users
  },
}
</script>

<style scoped>

</style>
