import moment from 'moment';
<template>
  <div>
    <a-table
      :scroll="{x:1300}"
      bordered
      :dataSource="value.data"
      :columns="value.columns"
      :loading="value.loading"
      :row-key="record => record.code"
      :pagination="value.pagination"
      @change="refreshResults"
    >
      <div slot="filterDropdown"
           slot-scope="{ setSelectedKeys, selectedKeys, confirm, clearFilters, column }"
           class='custom-filter-dropdown'>
        <a-input
          v-ant-ref="c => searchInput = c"
          :placeholder="`Buscar por ${column.title}`"
          :value="selectedKeys[0]"
          @change="e => setSelectedKeys(e.target.value ? [e.target.value] : [])"
          @pressEnter="() => handleSearch(selectedKeys, confirm)"
          style="width: 188px; margin-bottom: 8px; display: block;"
        />
        <a-button
          type='primary'
          @click="() => handleSearch(selectedKeys, confirm)"
          icon="search"
          size="small"
          style="width: 90px; margin-right: 8px"
        >Buscar
        </a-button>
        <a-button
          @click="() => handleReset(clearFilters)"
          size="small"
          style="width: 90px"
        >Limpiar
        </a-button>
      </div>
      <a-icon slot="filterIcon" type='search' slot-scope="filtered"
              :style="{ color: filtered ? '#108ee9' : undefined }"/>
      <template slot="customRender" slot-scope="text">
        <span v-if="searchText">
          <template
            v-for="(fragment, i) in text.toString().split(new RegExp(`(?<=${searchText})|(?=${searchText})`, 'i'))">
            <mark v-if="fragment.toLowerCase() === searchText.toLowerCase()" :key="i"
                  class="highlight">{{ fragment }}</mark>
            <template v-else>{{ fragment }}</template>
          </template>
        </span>
        <template v-else>{{ text }}</template>
      </template>
      <template slot="servicePlanning" slot-scope="text, record">
        <strong class="text-center">
          {{ record.servicePlannings.sap_programming_code }}
        </strong>
      </template>
      <template slot="users" slot-scope="text, record">
        <div class="row">
          <div class="col-12">
            <small>
              <strong class="text-center">
                Recibió Inspección:
              </strong>
            </small>
            <span class="small">
              {{ record.recepcionist.users.name }}
            </span>
          </div>
        </div>
        <hr class="bg-primary">
        <div class="row">
          <div class="col-12">
            <small><strong class="text-center">Supervisó Inspección: </strong></small> <span
            class="small">{{ record.supervisor.users.name }}</span>
          </div>
        </div>
      </template>
      <template slot="myCustomColumns" slot-scope="text, record">
        <slot name="details" :record="record"/>
      </template>
      <template slot="recepcionist" slot-scope="text, record">
        <strong class="text-center">{{ record.recepcionist.users.name }}</strong>
      </template>
      <template slot="supervisor" slot-scope="text, record">
        <strong class="text-center">{{ record.supervisor.users.name }}</strong>
      </template>
      <template slot="dated_at" slot-scope="text, record">
        <div class="text-center">
          {{
            moment(text).format('YYYY-MM-DD HH:mm')
          }}
          <div style="display: none">
            {{ record }}
          </div>
        </div>
      </template>
      <template slot="lab" slot-scope="text, record">
        <strong class="text-primary" v-show="record.lab_checks">
          <b-icon icon="check-circle-fill"></b-icon>
          Si requiere</strong>
        <strong class="text-danger" v-show="!record.lab_checks">
          <b-icon icon="x-circle-fill"></b-icon>
          No requiere</strong>
      </template>
      <template slot="collectors" slot-scope="record">
        <div v-if="record.collectors != null">
          <span v-if="Object.keys(record.collectors).length > 0">
            {{ record.collectors.name }}
          </span>
          <span v-else>NA</span>
        </div>
        <span v-else>NA</span>
      </template>
      <template slot="is_discharge_closes" slot-scope="record">
        <strong class="text-primary" v-show="record">
          <b-icon icon="check-circle-fill"></b-icon>
          Cerrado
        </strong>
        <strong class="text-danger" v-show="!record">
          <b-icon icon="x-circle-fill"></b-icon>
          No Cerrado
        </strong>
      </template>
      <!-- Custom Columns -->
      <a slot="action" slot-scope="data">
        <div v-show="buttonType === 'discharge'">
          <div class="p-1">
            <responsive-button variant="secondary"
                               pill size="sm" responsive="md"
                               text="Editar" icon="pencil-square"
                               @ClickEvent="showModal('edit', data)"
            />
          </div>
          <div class="p-1" v-show="!data.is_discharge_close">
            <responsive-button v-if="!LOCK_SERVICE" variant="danger"
                               pill size="sm" responsive="md"
                               text="Eliminar" icon="trash-fill"
                               @ClickEvent="testDelete(data)"
            />
          </div>
        </div>
        <div v-show="buttonType !== 'discharge'">
          <responsive-button v-if="!LOCK_SERVICE" variant="success"
                             pill size="sm" responsive="md"
                             text="Calcular" icon="calculator-fill"
                             @ClickEvent="calculateInformation(data)"
          />
        </div>
      </a>
    </a-table>
  </div>
</template>
<script>
import { mapGetters } from 'vuex'

export default {
  name: 'ManifestTable',
  props: {
    value: {
      type: [Object],
      required: true,
    },
    buttonType: {
      type: [String],
      default: 'discharge',
    },
  },
  data() {
    return {
      // Varialbles propias para el funcionamiento de la tabla
      searchText: '',
      searchInput: null,
      resourceType: 'manifests',
      // Varialbles propias para el funcionamiento de la tabla
    }
  },
  computed: {
    ...mapGetters('servicePlannings', ['LOCK_SERVICE']),
  },
  watch: {
    searchInput(newValue) {
      this.$emit('searchInput', newValue)
    },
    value(newValue) {
      this.$emit('input', newValue)
    },
  },
  methods: {
    handleSearch(selectedKeys, confirm) {
      confirm()
      this.searchText = selectedKeys[0]
    },
    handleReset(clearFilters) {
      clearFilters()
      this.searchText = ''
    },
    showModal(action, data = {}) {
      this.$emit('Modal', action, data)
    },
    Selected(data = {}) {
      this.$emit('Select', data)
    },
    refreshResults(paginationEvent = {}) {
      this.$emit('Filter', paginationEvent)
    },
    calculateInformation(manifestData = {}) {
      this.$emit('Calculate', manifestData)
    },
    testDelete(data) {
      this.$emit('Delete', data.id)
    },
  },
  mounted() {
  },
}
</script>

<style scoped>
.custom-filter-dropdown {
  padding: 8px;
  border-radius: 4px;
  background: #fff;
  box-shadow: 0 2px 8px rgba(0, 0, 0, .15);
}

.highlight {
  background-color: rgb(255, 192, 105);
  padding: 0;
}
</style>
