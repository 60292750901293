<template>
  <div class="my-2 px-3">
    <!-- Barra de Búsqueda -->
    <detail-catalog-bar @getData="getData"
                        :sortByOptions="sortByOptions"
                        :busy="tableObject.loading"
                        @searchValue="searchValue = $event"
                        @sortByValue="sortByValue = $event"
                        @sortDirectionValue="sortDirectionValue = $event"
                        @perPageValue="perPageValue = $event"
                        @currentPageValue="currentPageValue = $event"
    />
    <!-- Barra de Búsqueda -->
    <!-- FILTRO DE PLANTAS Y CLIENTES-->
    <div class="row justify-content-center">
      <div class="col-6 mb-4" >
        <b-form-group
          id="plant-group"
          label="Planta"
          label-for="plant"
        >
          <b-form-select v-model="filterWorkCenter" :options="work_centers"
                         id="plant" label="Planta" @change="changeFilterWorkCenter()"
                         value-field="jsonApi.id" text-field="name">
            <template slot="first">
              <b-form-select-option value="" >Todas las Plantas</b-form-select-option>
            </template>
          </b-form-select>
        </b-form-group>
      </div>
      <div class="col-6 mb-4" >
        <b-form-group
          id="client-group"
          label="Cliente"
          label-for="client"
        >
          <b-form-select v-model="filterClient" :options="clients"
                         id="client" label="Cliente" @change="changeFilterWorkCenter()"
                         value-field="jsonApi.id" text-field="name">
            <template slot="first">
              <b-form-select-option value="" >Todas los Clientes</b-form-select-option>
            </template>
          </b-form-select>
        </b-form-group>
      </div>
    </div>

    <!-- FILTRO DE PLANTAS Y CLIENTES-->

    <!--Botonera  -->
    <div class="row justify-content-center pb-3">
      <div class="col-12 text-right">
        <b-button @click="OpenModal('store')" pill  variant="primary" >
          <b-icon-plus/> Nuevo
        </b-button>
      </div>
    </div>
    <!--Botonera  -->
    <!-- Modal -->
    <b-modal :title="`Detalle ${resourceName}`"
             ref="modal-form"
             size="lg"
             @hidden="CloseModal()"
    >
      <b-container fluid>
        <!-- Form with Model -->
        <a-form-model
          ref="rule-Form"
          :model="form"
          :rules="rules"
          layout="vertical"
          :wrapper-col="{ span: 24}"
        >
          <a-form-model-item ref="name" label="Nombre del Residuo" prop="name" >
            <a-textarea  v-model.trim="form.name" :rows="2"/>
          </a-form-model-item>
          <a-form-model-item ref="work_center_id" label="Centro de Trabajo (Planta)" prop="work_center_id" >
            <a-select v-model="form.work_center_id"
                      placeholder="Seleccionar el Centro de Trabajo"
                      show-search :filter-option="filterOption">
              <a-select-option  v-for="option in work_centers" :key="option.jsonApi.id" :value="option.jsonApi.id" >
                {{option.name}}
              </a-select-option>
            </a-select>
          </a-form-model-item>

          <a-form-model-item ref="dangerous_type_id" label="Tipo de Material Peligroso" prop="dangerous_type_id" >
            <a-select v-model="form.dangerous_type_id"
                      placeholder="Seleccionar el Tipo de Material Peligroso"
                      show-search :filter-option="filterOption">
              <a-select-option  v-for="option in dangerous_types" :key="option.jsonApi.id" :value="option.jsonApi.id" >
                {{option.name}}
              </a-select-option>
            </a-select>
          </a-form-model-item>
          <a-form-model-item ref="current_id" label="Corrientes" prop="current_id" >
            <a-select v-model="form.current_id"
                      placeholder="Seleccionar la Corriente"
                      show-search :filter-option="filterOption">
              <a-select-option  v-for="option in currents" :key="option.jsonApi.id" :value="option.jsonApi.id" >
                {{option.name}}
              </a-select-option>
            </a-select>
          </a-form-model-item>
          <a-form-model-item ref="rme_rp_id" label="Rme Rps" prop="rme_rp_id" >
            <a-select v-model="form.rme_rp_id"
                      placeholder="Seleccionar el Rme Rp"
                      show-search :filter-option="filterOption">
              <a-select-option  v-for="option in rme_rps" :key="option.jsonApi.id" :value="option.jsonApi.id" >
                {{option.code}}
              </a-select-option>
            </a-select>
          </a-form-model-item>
          <a-form-model-item ref="semarnat_number" label="Número SEMARNAT" prop="semarnat_number" >
            <a-input v-model.trim="form.semarnat_number" :disabled="id !=='0'"/>
          </a-form-model-item>
          <b-form-checkbox-group
            id="checkbox-group-2"
            v-model="selected"
            :options="cretibOptions"
            :state="cretibState"
            name="checkbox-validation"
          >
            <b-form-invalid-feedback :state="cretibState">
              {{ errorCretibMessage }}
            </b-form-invalid-feedback>
            <b-form-valid-feedback :state="cretibState">
              Ha seleccionado un registro de CRETIB
            </b-form-valid-feedback>
          </b-form-checkbox-group>
          <label for="mixture" class="pt-4">Mezcla</label>
          <b-form-select
            v-model="form.mixture"
            :options="mixtureOptions"
            class="mb-3"
            value-field="item"
            text-field="name"
          ></b-form-select>
          <a-form-model-item ref="clientPivot" label="Clientes" prop="clientPivot" >
            <a-select v-model="form.clientPivot" disabled mode="multiple"
                      placeholder="Seleccionar Clientes"
                      show-search :filter-option="filterOption">
              <a-select-option  v-for="option in clients" :key="option.jsonApi.id" :value="option.jsonApi.id" >
                {{option.name}}
              </a-select-option>
            </a-select>
          </a-form-model-item>
          <div class="row justify-content-center" v-show="!load">
            <responsive-button variant="primary" pill size="sm" text="Guardar" responsive="md" icon="archive-fill" @ClickEvent="onSubmit"/>
            <responsive-button v-if="id !== '0'" variant="danger" pill size="sm" text="Eliminar" responsive="md" icon="trash" @ClickEvent="onDelete"/>
          </div>
        </a-form-model>
        <!-- Form with Model -->

        <!-- Spinner -->
        <my-spinner :load="load"/>

      </b-container>
      <template v-slot:modal-footer>
        <simple-modal-footer @ClickEvent="CloseModal"/>
      </template>
    </b-modal>
    <!-- Modal -->
    <!-- Tabla -->
    <a-table
      :scroll="{ x: 1000}"
      :dataSource="tableObject.data"
      :columns="tableObject.columns"
      :loading="tableObject.loading"
      :row-key="record => record.code"
      :pagination="tableObject.pagination"
      bordered
      @change="getData"
    >
      <div slot="filterDropdown"
           slot-scope="{ setSelectedKeys, selectedKeys, confirm, clearFilters, column }"
           class='custom-filter-dropdown'>
        <a-input
          v-ant-ref="c => searchInput = c"
          :placeholder="`Buscar por ${column.title}`"
          :value="selectedKeys[0]"
          @change="e => setSelectedKeys(e.target.value ? [e.target.value] : [])"
          @pressEnter="() => handleSearch(selectedKeys, confirm)"
          style="width: 188px; margin-bottom: 8px; display: block;"
        />
        <a-button
          type='primary'
          @click="() => handleSearch(selectedKeys, confirm)"
          icon="search"
          size="small"
          style="width: 90px; margin-right: 8px"
        >Buscar</a-button>
        <a-button
          @click="() => handleReset(clearFilters)"
          size="small"
          style="width: 90px"
        >Limpiar</a-button>
      </div>
      <a-icon slot="filterIcon" type='search' slot-scope="filtered" :style="{ color: filtered ? '#108ee9' : undefined }"/>

      <div slot="main" slot-scope="data">
        <div class="row my-0 py-0">
          <div class="col-6 text-left">
            <strong>Clave: </strong>
          </div>
          <div class="col-6 text-right">
            <span> {{data.code}}</span>
          </div>
        </div>
        <hr class="bg-primary">
        <div class="row my-0 py-0">
          <div class="col-12 text-center">
            <span> {{data.name}}</span>
          </div>
        </div>
        <hr class="bg-primary">
        <div class="row my-0 py-0">
          <div class="col-6 text-left">
            <strong>SEMARNAT: </strong>
          </div>
          <div class="col-6 text-right">
            <span> {{data.semarnat_number}}</span>
          </div>
        </div>
      </div>

      <div slot="details" slot-scope="data">
        <div class="row my-0 py-0">
          <div class="col-6 text-left">
            <strong>Planta : </strong>
          </div>
          <div class="col-6 text-right">
            <span> {{data.workCenterRelation.name}}</span>
          </div>
        </div>
        <hr class="bg-primary">
        <div class="row my-0 py-0">
          <div class="col-6 text-left">
            <strong>Peligrosidad : </strong>
          </div>
          <div class="col-6 text-right">
            <span> {{data.dangerousTypeRelation.name}}</span>
          </div>
        </div>
        <hr class="bg-primary">
        <div class="row my-0 py-0">
          <div class="col-6 text-left">
            <strong>Corriente : </strong>
          </div>
          <div class="col-6 text-right">
            <span> {{ data.currentRelation.code }} - {{ data.currentRelation.name }}</span>
          </div>
        </div>
        <hr class="bg-primary">
        <div class="row my-0 py-0">
          <div class="col-6 text-right">
            <strong>Mezcla : </strong>
          </div>
          <div class="col-6 text-left">
            <b-icon icon="check-circle-fill" variant="success" v-if="data.mixture"/>
            <b-icon icon="x-circle-fill" variant="danger" v-else/>
          </div>
        </div>
      </div>

      <div slot="clients" slot-scope="clients">
        <ul v-if="Object.keys(clients).length">
          <li v-for="client in clients" :key="client.code">{{client.name}}</li>
        </ul>
      </div>

      <div slot="rme_rp" slot-scope="row">
        <small>
          {{ row.rmeRpRelation.code }}
        </small>
      </div>

      <div slot="action" slot-scope="row">
        <responsive-button variant="info" pill size="sm" responsive="sm"
                           text="Seleccionar" icon="hand-index-thumb" @ClickEvent="Selected(row)"/>
      </div>
    </a-table>
    <!-- Tabla -->
  </div>
</template>

<script>
import DetailCatalogBar from '../components/searchBar'
import { notification, Modal } from 'ant-design-vue'
import { apiRestMixin } from '@/mixins/apiRestMixin'

export default {
  name: 'materialForm',
  props: {
    clientId: {
      default: null,
      type: String,
      required: false,
    },
  },
  components: {
    DetailCatalogBar,
  },
  mixins: [apiRestMixin],
  data() {
    return {
      // <!-- Constantes -->
      selected: [],
      errorCretibMessage: 'No ha elegido ningún dato del CRETIB. Favor de verificar su respuesta',
      cretibOptions: [
        { text: 'C', value: 'c' },
        { text: 'R', value: 'r' },
        { text: 'E', value: 'e' },
        { text: 'T', value: 't' },
        { text: 'Te', value: 'te' },
        { text: 'Th', value: 'th' },
        { text: 'Tt', value: 'tt' },
        { text: 'I', value: 'i' },
        { text: 'B', value: 'b' },
      ],
      cretibArray: [
        'c',
        'r',
        'e',
        't',
        'te',
        'th',
        'tt',
        'i',
        'b',
      ],
      mixtureOptions: [
        { item: true, name: 'SI' },
        { item: false, name: 'NO' },
      ],
      resource: 'materials',
      resourceName: 'Residuos',
      routes: [
        {
          name: 'home',
          breadcrumbName: 'Inicio',
          bIcon: 'house-fill',
        },
        {
          name: 'catalogs',
          breadcrumbName: 'Catálogos',
          aIcon: '',
        },
        {
          breadcrumbName: 'Residuos',
          aIcon: '',
        },
      ],
      searchInput: null,
      // <!-- Table -->
      tableObject: {
        data: [],
        columns: [
          {
            title: 'Info.General',
            dataIndex: '',
            key: 'main',
            thClass: 'text-center',
            class: 'text-center small',
            scopedSlots: {
              customRender: 'main',
            },
          },
          {
            title: 'Rme Rp',
            label: 'Corriente',
            key: 'rme_rp',
            thClass: 'text-center',
            class: 'text-center small',
            width: '100px',
            scopedSlots: {
              customRender: 'rme_rp',
            },
          },
          {
            title: 'Detalles',
            dataIndex: '',
            key: 'details',
            thClass: 'text-center',
            class: 'text-center small',
            scopedSlots: {
              customRender: 'details',
            },
          },
          {
            title: 'Clientes',
            dataIndex: 'clients',
            key: 'clients',
            thClass: 'text-center',
            class: 'text-center small',
            scopedSlots: {
              customRender: 'clients',
            },
          },
          {
            title: 'Acción',
            key: 'x',
            fixed: 'right',
            class: 'text-center small',
            scopedSlots: {
              customRender: 'action',
            },
          }],
        loading: false,
        pagination: {
          current: 1,
          pageSize: 10,
          from: 0,
          to: 0,
          total: 0,
          lastPage: 0,
        },
        perPageValue: 10,
        currentPageValue: 1,
      },
      // <!-- Table -->
      // <!-- Barra de Control -->
      searchValue: '',
      sortByValue: '',
      sortByDefaultValue: 'name',
      sortDirectionValue: '',
      sortByOptions: [
        { value: '', text: 'Ordenar por', disabled: true },
        { value: 'code', text: 'Clave del Residuo' },
        { value: 'name', text: 'Nombre del Residuo' },
      ],
      // <!-- Barra de Control -->
      // <!-- Constantes -->
      // <!-- Form with Model -->
      id: '0',
      form: {
        // code: '',
        name: '',
        work_center_id: undefined,
        clientPivot: [],
        dangerous_type_id: undefined,
        current_id: undefined,
        rme_rp_id: undefined,
        semarnat_number: '',
        c: false,
        r: false,
        e: false,
        t: false,
        te: false,
        th: false,
        tt: false,
        i: false,
        b: false,
        mixture: false,
      },
      // arrays selects
      work_centers: [],
      dangerous_types: [],
      currents: [],
      rme_rps: [],
      clients: [],
      // Reglas de validación
      rules: {
        name: [
          { required: true, message: 'El campo Nombre del Residuo es obligatorio.', trigger: 'blur' },
          { min: 3, max: 400, message: 'El campo Nombre del Residuo debe contener entre 3 y 400 caracteres.', trigger: 'blur' },
          { type: 'string', message: 'El campo Nombre del Residuo debe ser de tipo texto.', trigger: 'blur' },
        ],
        work_center_id: [
          { required: true, message: 'El campo Centro de Trabajo es obligatorio.', trigger: 'blur' },
        ],
        dangerous_type_id: [
          { required: true, message: 'El campo Tipo de Material Peligroso es obligatorio.', trigger: 'blur' },
        ],
        current_id: [
          { required: true, message: 'El campo Corriente es obligatorio.', trigger: 'blur' },
        ],
        rme_rp_id: [
          { required: true, message: 'El campo Rme Rp es obligatorio.', trigger: 'blur' },
        ],
        semarnat_number: [
          { required: true, message: 'El campo Número de Semarnat es obligatorio.', trigger: 'blur' },
          { min: 2, max: 50, message: 'El campo Número de Semarnat debe contener entre 2 y 50 caracteres.', trigger: 'blur' },
          { type: 'string', message: 'El campo Número de Semarnat debe ser de tipo texto.', trigger: 'blur' },
        ],
        mixture: [
          { required: true, message: 'El campo Mezcla es obligatorio.', trigger: 'blur' },
        ],
      },
      // <!-- Form with Model -->
      load: false,
      // FILTRO PLANTAS
      filterWorkCenter: '',
      filterClient: '',
    }
  },
  computed: {
    sortDirection() {
      if (this.sortDirectionValue === '') {
        return ''
      } else if (this.sortDirectionValue === '-') {
        return '-'
      } else {
        return ''
      }
    },
    sortValue() {
      if (this.sortByValue === '') {
        return this.sortByDefaultValue
      } else {
        return this.sortByValue
      }
    },
    cretibState() {
      return this.destructuringCretib().includes(true)
    },
  },
  methods: {
    filterOption(input, option) {
      return (
        option.componentOptions.children[0].text.toLowerCase().indexOf(input.toLowerCase()) >= 0
      )
    },
    // Selected
    Selected(data) {
      this.$emit('Selected', data)
    },
    changeFilterWorkCenter() {
      this.form.work_center_id = this.filterWorkCenter
      this.getData()
    },
    // Selected
    // <!-- Get,Paginate,Search & Filter Data  -->
    getData(paginationEvent = {}) {
      const params = {
        sort: `${this.sortDirection}${this.sortValue}`,
        include: 'clients',
      }
      if (this.filterClient !== '') {
        params['filter[clients]'] = this.filterClient
      }

      if (this.filterWorkCenter !== '') {
        params['filter[workCenters]'] = this.filterWorkCenter
      }
      if (this.searchValue !== '') {
        params['filter[search]'] = this.searchValue
      }
      this.getJsonApiTable(paginationEvent, this.resource, this.tableObject, params)
    },
    // <!-- Get,Paginate,Search & Filter Data  -->
    // <!-- Get array selects data  -->
    async getDangerous_types() {
      const resp = await this.getJsonApiResources('dangerousTypes', { sort: 'name' })
      if (resp) this.dangerous_types = resp
    },
    async getWork_centers() {
      const resp = await this.getJsonApiResources('workCenters', { sort: 'name' })
      if (resp) this.work_centers = resp
    },
    async getCurrents() {
      const resp = await this.getJsonApiResources('currents', { sort: 'name' })
      if (resp) this.currents = resp
    },
    async getRme_rps() {
      const resp = await this.getJsonApiResources('rmeRps', { sort: 'code' })
      if (resp) this.rme_rps = resp
    },
    async getClients() {
      const resp = await this.getJsonApiResources('clients', { sort: 'name' })
      if (resp) this.clients = resp
    },
    // <!-- Get array selects data  -->
    // <!-- Open & Close Modal Logic  -->
    OpenModal(action, data = {}) {
      this.$refs['modal-form'].show()
      this.getDangerous_types()
      this.getWork_centers()
      this.getClients()
      this.getCurrents()
      this.getRme_rps()
      this.getClients()
      if (Object.keys(data).length === 0) {
        //  Store case
        this.id = '0'
        this.form.code = ''
        this.form.name = ''
        this.form.dangerous_type_id = undefined
        this.form.current_id = undefined
        this.form.rme_rp_id = undefined
        this.form.semarnat_number = ''
        this.form.mixture = false
        this.form.client_id = undefined
        // if (typeof this.form.work_center_id !== 'string') {
        this.form.work_center_id = undefined
        // }
        if (this.clientId != null) {
          this.form.clientPivot.push(this.clientId)
        } else this.form.clientPivot = []
      } else {
        // Update case
        this.id = data.jsonApi.id
        this.form.code = data.code
        this.form.name = data.name
        this.form.work_center_id = data.workCenterRelationKey
        this.form.dangerous_type_id = data.dangerousTypeRelationKey
        this.form.current_id = data.currentRelationKey
        this.form.rme_rp_id = data.rmeRpRelationKey
        this.form.client_id = data.clientRelationKey
        this.form.semarnat_number = data.semarnat_number
        this.form.mixture = data.mixture
        this.form.c = data.c
        this.form.r = data.r
        this.form.e = data.e
        this.form.t = data.t
        this.form.te = data.te
        this.form.th = data.th
        this.form.tt = data.tt
        this.form.i = data.i
        this.form.b = data.b
        // Asignación de las variables
        this.selected[0] = (data.c === true) ? 'c' : ''
        this.selected[1] = (data.r === true) ? 'r' : ''
        this.selected[2] = (data.e === true) ? 'e' : ''
        this.selected[3] = (data.t === true) ? 't' : ''
        this.selected[4] = (data.te === true) ? 'te' : ''
        this.selected[5] = (data.th === true) ? 'th' : ''
        this.selected[6] = (data.tt === true) ? 'tt' : ''
        this.selected[7] = (data.i === true) ? 'i' : ''
        this.selected[8] = (data.b === true) ? 'b' : ''
        this.destructuringCretib()
        this.getClientsFromMaterials(data.clients)
      }
    },
    CloseModal() {
      this.$refs['modal-form'].hide()
      this.resetForm()
    },
    getClientsFromMaterials(clients) {
      if (Object.keys(clients).length) {
        Object.entries(clients).forEach(([key, value]) => {
          this.form.clientPivot.push(value.jsonApi.id)
        })
      } else this.form.clientPivot = []
    },
    formatSelected() {
      this.selected[0] = ''
      this.selected[1] = ''
      this.selected[2] = ''
      this.selected[3] = ''
      this.selected[4] = ''
      this.selected[5] = ''
      this.selected[6] = ''
      this.selected[7] = ''
      this.selected[8] = ''
    },
    // <!-- Open & Close Modal Logic  -->
    // <!-- Store Logic  -->
    destructuringCretib() {
      const cretibResponseArray = []
      this.cretibArray.forEach(element => {
        const includeResponse = this.selected.includes(element)
        cretibResponseArray.push(includeResponse)
      })
      this.form.c = cretibResponseArray[0]
      this.form.r = cretibResponseArray[1]
      this.form.e = cretibResponseArray[2]
      this.form.t = cretibResponseArray[3]
      this.form.te = cretibResponseArray[4]
      this.form.th = cretibResponseArray[5]
      this.form.tt = cretibResponseArray[6]
      this.form.i = cretibResponseArray[7]
      this.form.b = cretibResponseArray[8]
      // console.log([this.c, this.r, this.e, this.t, this.te, this.th, this.tt, this.i, this.b])
      return cretibResponseArray
    },
    async onSubmit() {
      this.$refs['rule-Form'].validate(async (valid) => {
        if (valid) {
          let action = 'post'
          const relationships = {
            dangerousType: {
              data: { id: this.form.dangerous_type_id, type: 'dangerousTypes' },
            },
            workCenter: {
              data: { id: this.form.work_center_id, type: 'workCenters' },
            },
            current: {
              data: { id: this.form.current_id, type: 'currents' },
            },
            rmeRp: {
              data: { id: this.form.rme_rp_id, type: 'rmeRps' },
            },
          }
          const formObject = {
            name: `${this.form.name}`,
            semarnat_number: `${this.form.semarnat_number}`,
            c: this.form.c,
            r: this.form.r,
            e: this.form.e,
            t: this.form.t,
            te: this.form.te,
            th: this.form.th,
            tt: this.form.tt,
            i: this.form.i,
            b: this.form.b,
            mixture: this.form.mixture,
            clientPivot: this.form.clientPivot,
            jsonApi: {
              type: this.resource,
              relationships: relationships,
            },
          }

          if (this.id !== '0') {
            action = 'patch'
            // Update Object
            formObject.jsonApi.id = this.id
            delete formObject.name
            delete formObject.semarnat_number
            // Update Object
          }
          // Envio del Objecto
          this.load = true
          await this.setJsonApiResource(formObject, action, {}, (response) => {
            this.getData()
            this.CloseModal()
            notification.success({
              message: 'Registro guardado exitosamente',
            })
            this.$emit('Created', response)
          })
          this.load = false
          // Envio del Objecto
        } else {
          // Fallo de validación
          return false
        }
      })
    },
    onDelete() {
      const me = this
      Modal.confirm({
        title: '¿Esta seguro de eliminar este registro?',
        content: 'Esta operación es irreversible',
        okText: 'Sí',
        okType: 'danger',
        cancelText: 'No',
        zIndex: 3000,
        onOk() {
          // Delete Object
          const deleteObject = {
            jsonApi: {
              type: me.resource,
              id: me.id,
            },
          }
          // Delete Object
          me.$store.dispatch('jv/delete', [deleteObject, {}])
            .then((data) => {
              notification.success({
                message: 'Registro eliminado exitosamente',
              })
              me.getData()
              me.load = false
              me.CloseModal()
            })
            .catch((errors) => {
              console.log(errors)
              this.load = false
            })
        },
        onCancel() {
          notification.info({
            message: 'No se ha eliminado el registro',
          })
        },
      })
    },
    // <!-- Store Logic  -->
    resetForm() {
      // this.$refs['rule-Form'].resetFields()
      this.id = '0'
      this.form.name = ''
      this.form.work_center_id = undefined
      this.form.clientPivot = []
      this.form.dangerous_type_id = undefined
      this.form.current_id = undefined
      this.form.rme_rp_id = undefined
      this.form.semarnat_number = ''
      this.form.c = false
      this.form.r = false
      this.form.e = false
      this.form.t = false
      this.form.te = false
      this.form.th = false
      this.form.tt = false
      this.form.i = false
      this.form.b = false
      this.form.mixture = false
    },
  },
  mounted() {
    if (this.clientId !== null) {
      this.filterClient = this.clientId
    }
    this.getData()
    this.getClients()
    this.getWork_centers()
  },
}
</script>
