<template>
  <a-table
    :dataSource="tableObject.data"
    :columns="columns"
    :loading="tableObject.loading"
    :row-key="record => record.id"
    :pagination="tableObject.pagination"
    @change="refreshResults"
    bordered
    :scroll="{ x: 1300 }"
  >
    <div slot="filterDropdown"
         slot-scope="{ setSelectedKeys, selectedKeys, confirm, clearFilters, column }"
         class='custom-filter-dropdown'>
      <a-input
        v-ant-ref="c => searchInput = c"
        :placeholder="`Buscar por ${column.title}`"
        :value="selectedKeys[0]"
        @change="e => setSelectedKeys(e.target.value ? [e.target.value] : [])"
        @pressEnter="() => handleSearch(selectedKeys, confirm)"
        style="width: 188px; margin-bottom: 8px; display: block;"
      />
      <a-button
        type='primary'
        @click="() => handleSearch(selectedKeys, confirm)"
        icon="search"
        size="small"
        style="width: 90px; margin-right: 8px"
      >Buscar</a-button>
      <a-button
        @click="() => handleReset(clearFilters)"
        size="small"
        style="width: 90px"
      >Limpiar</a-button>
    </div>
    <a-icon slot="filterIcon" type='search' slot-scope="filtered" :style="{ color: filtered ? '#108ee9' : undefined }"/>
    <template slot="customRender" slot-scope="text">
        <span v-if="searchText">
          <template
            v-for="(fragment, i) in text.toString().split(new RegExp(`(?<=${searchText})|(?=${searchText})`, 'i'))">
              <mark v-if="fragment.toLowerCase() === searchText.toLowerCase()" :key="i" class="highlight">{{fragment}}</mark>
              <template v-else>{{fragment}}</template>
          </template>
        </span>
      <template v-else>{{text}}</template>
    </template>
    <template slot="detail" slot-scope="record">
      <strong>Residuo: </strong>{{record.sapMaterialContracts.materials.name}}
      <hr>
      <strong>Residuo SAP: </strong>{{record.sapMaterialContracts.sapMaterials.code}} - {{record.sapMaterialContracts.sapMaterials.name}}
      <hr>
      <strong>Corrientes: </strong>{{record.sapMaterialContracts.materials.currentRelation.name}}
    </template>
    <!-- <template slot="name" slot-scope="record">
      {{record.sapMaterialContracts.materials.name}}
    </template>
    <template slot="sap_name" slot-scope="record">
      {{record.sapMaterialContracts.sapMaterials.code}} - {{record.sapMaterialContracts.sapMaterials.name}}
    </template>
    <template slot="currents" slot-scope="record">
      {{record.sapMaterialContracts.materials.currentRelation.name}}
    </template> -->
    <template slot="dangerous" slot-scope="record">
      {{record.name}}
    </template>
    <template slot="disposal" slot-scope="record">
      {{record.name}}
    </template>
    <template slot="container_quantity" slot-scope="record">
      {{ (record.manifestData != null) ? ((record.manifestData.container_quantity != null) ? record.manifestData.container_quantity : '-') : '-' }}
    </template>
    <template slot="container_type_id" slot-scope="record">
      {{ (record.manifestData != null) ? ((record.manifestData.container_types != null) ? record.manifestData.container_types.name : '-') : '-' }}
    </template>
    <template slot="quantity" slot-scope="record">
      {{ (record.manifestData != null) ? record.manifestData.quantity : '-'}}
    </template>
    <template slot="u_m_s_id" slot-scope="record">
      {{ (record.manifestData != null) ? record.manifestData.ums.sap_code : ''}} - {{ (record.manifestData != null) ? record.manifestData.ums.name : ''}}
    </template>
    <template slot="container_quantity_real" slot-scope="record">
      {{ (record.realData != null) ? ((record.realData.container_quantity != null) ? record.realData.container_quantity : '-') : '-' }}
    </template>
    <template slot="container_type_id_real" slot-scope="record">
      {{ (record.realData != null) ? ((record.realData.container_types != null) ? record.realData.container_types.name : '-') : '-' }}
    </template>
    <template slot="diff_quantity" slot-scope="record">
      {{ (record.manifestData != null && record.manifestData != null) ? ((record.manifestData.container_quantity != null && record.realData.container_quantity != null) ? (record.manifestData.container_quantity - record.realData.container_quantity) : '-') : '-' }}
    </template>
    <template slot="incidents" slot-scope="record">
      {{record.code}} - {{record.name}}
    </template>
    <!-- Columnas Personalizadas -->
    <a slot="actions" slot-scope="record">
      <a-row v-show="typeForm === 'inspectionMaterial'">
        <div v-show="!record.authorize_check_record">
          <responsive-button responsive="sm"
                             icon="pencil-square"
                             variant="secondary" pill size="sm"
                             title="Editar"
                             text=""
                             @ClickEvent="showModal('edit', record)"
          />
          <responsive-button v-if="!LOCK_SERVICE" responsive="sm"
                             icon="trash-fill"
                             variant="danger" pill size="sm"
                             class="ml-2"
                             title="Eliminar"
                             text=""
                             @ClickEvent="deleteRecord(record)"
          />
        </div>
        <div v-show="record.authorize_check_record">
          El registro ha sido autorizado
        </div>
      </a-row>
      <a-row v-show="typeForm === 'inspectionDischarge'">
        <a-row>
          <div v-show="record.authorize_check_record">
            <span v-show="record.authorize_check === 'Autorizado'" class="text-success">
              <b-icon icon="check-circle-fill"></b-icon> {{ record.authorize_check }}
            </span>
            <span v-show="record.authorize_check === 'Rechazado'" class="text-danger">
              <b-icon icon="x-circle-fill"></b-icon> {{ record.authorize_check }}
            </span>
            <span v-show="record.authorize_check === 'Cuarentena'" class="text-warning">
              <b-icon icon="exclamation-triangle-fill"></b-icon> {{ record.authorize_check }}
            </span>
          </div>
          <div v-show="!record.authorize_check_record">
            Sin Autorización
          </div>
        </a-row>
        <a-row>
          <responsive-button responsive="sm"
                             icon="pencil-square"
                             variant="secondary" pill size="sm"
                             title="Revisión"
                             text=""
                             v-show="!record.warehouse_check"
                             @ClickEvent="showModal('operationValidate', record)"
          />
        </a-row>
      </a-row>
      <a-row v-show="typeForm === 'dischargeLab'">
        <a-row>
          <div v-show="record.authorize_check_record">
            <span v-show="record.authorize_check === 'Autorizado'" class="text-success">
              <b-icon icon="check-circle-fill"></b-icon> {{ record.authorize_check }}
            </span>
            <span v-show="record.authorize_check === 'Rechazado'" class="text-danger">
              <b-icon icon="x-circle-fill"></b-icon> {{ record.authorize_check }}
            </span>
            <span v-show="record.authorize_check === 'Cuarentena'" class="text-warning">
              <b-icon icon="exclamation-triangle-fill"></b-icon> {{ record.authorize_check }}
            </span>
          </div>
          <div v-show="!record.authorize_check_record">
            Sin Autorización
          </div>
        </a-row>
        <hr class="bg-primary">
        <a-row>
          <div v-show="record.sampling_check_record">
            <span v-show="record.sampling_check === 'Muestreo'" class="text-warning">
              <b-icon icon="calculator-fill"></b-icon> {{ record.sampling_check }}
            </span>
            <span v-show="record.sampling_check === 'Inspección Visual'" class="text-primary">
              <b-icon icon="binoculars-fill"></b-icon> {{ record.sampling_check }}
            </span>
          </div>
          <div v-show="!record.sampling_check_record">
            Sin Prueba
          </div>
        </a-row>
        <hr class="bg-primary">
        <a-row>
          <span v-if="record.laboratory_check === '1' || record.laboratory_check === 'true' || record.laboratory_check === true || record.laboratory_check === 1 " class="text-primary">
            <b-icon icon="check-circle-fill"></b-icon> Laboratorio Afectado
          </span>
          <span v-else class="text-warning">
            <b-icon icon="x-circle-fill"></b-icon> Sin Afectar
          </span>
        </a-row>
        <hr class="bg-primary" v-show="record.authorize_check === 'Autorizado' && (record.laboratory_check === 'false' || record.laboratory_check === '0' || record.laboratory_check === 0 || record.laboratory_check === false)">
        <a-row>
          <responsive-button responsive="sm"
                             icon="pencil-square"
                             variant="secondary" pill size="sm"
                             title="Revisión"
                             text=""
                             @ClickEvent="showModal('labValidate', record)"
                             v-show="record.authorize_check === 'Autorizado' && (record.laboratory_check === 'false' || record.laboratory_check === '0' || record.laboratory_check === 0 || record.laboratory_check === false)"
          />
        </a-row>
      </a-row>
    </a>
  </a-table>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'wasteServiceTable',
  props: {
    tableObject: Object,
    typeForm: {
      default: 'inspectionDischarge',
      type: String,
    },
  },
  data () {
    return {
      // Varialbles propias para el funcionamiento de la tabla
      searchText: '',
      searchInput: null,
      // Varialbles propias para el funcionamiento de la tabla
      // Columnas de la tabla
      columns: [
        {
          title: 'Detalles del Residuo',
          dataIndex: '',
          key: 'detail',
          class: 'small text-center',
          scopedSlots: {
            customRender: 'detail',
          },
        },
        // {
        //   title: 'Residuo',
        //   dataIndex: '',
        //   key: 'name',
        //   class: 'small text-center',
        //   scopedSlots: {
        //     customRender: 'name',
        //   },
        // },
        // {
        //   title: 'Residuo SAP',
        //   dataIndex: '',
        //   key: 'sap_name',
        //   class: 'small text-center',
        //   scopedSlots: {
        //     customRender: 'sap_name',
        //   },
        // },
        // {
        //   title: 'Corrientes',
        //   dataIndex: '',
        //   key: 'currents',
        //   class: 'small text-center',
        //   scopedSlots: {
        //     customRender: 'currents',
        //   },
        // },
        {
          title: 'Peligrosidad',
          dataIndex: 'dangerousTypes',
          key: 'dangerousTypes',
          class: 'small text-center',
          scopedSlots: {
            customRender: 'dangerous',
          },
        },
        {
          title: 'Manifestado',
          class: 'small text-center',
          children: [
            {
              title: 'No. Contenedores',
              dataIndex: '',
              key: 'container_quantity',
              class: 'small text-center',
              scopedSlots: {
                customRender: 'container_quantity',
              },
            },
            {
              title: 'Tipo Contenedores',
              dataIndex: '',
              key: 'container_type_id',
              class: 'small text-center',
              scopedSlots: {
                customRender: 'container_type_id',
              },
            },
            {
              title: 'Cantidad Total',
              dataIndex: '',
              key: 'quantity',
              class: 'small text-center',
              scopedSlots: {
                customRender: 'quantity',
              },
            },
            {
              title: 'UM',
              dataIndex: '',
              key: 'u_m_s_id',
              class: 'small text-center',
              scopedSlots: {
                customRender: 'u_m_s_id',
              },
            },
          ],
        },
        {
          title: 'Real',
          class: 'small text-center',
          children: [
            {
              title: 'No. Contenedores',
              dataIndex: '',
              key: 'container_quantity_real',
              class: 'small text-center',
              scopedSlots: {
                customRender: 'container_quantity_real',
              },
            },
            {
              title: 'Tipo Contenedores',
              dataIndex: '',
              key: 'container_type_id_real',
              class: 'small text-center',
              scopedSlots: {
                customRender: 'container_type_id_real',
              },
            },
          ],
        },
        {
          title: 'Diferencias',
          class: 'small text-center',
          children: [
            {
              title: 'No. Contenedores',
              dataIndex: '',
              key: 'diff_quantity',
              class: 'small text-center',
              scopedSlots: {
                customRender: 'diff_quantity',
              },
            },
          ],
        },
        {
          title: 'Incidentes',
          dataIndex: 'dischargeMaterialIncidents',
          key: 'dischargeMaterialIncidents',
          class: 'small text-center',
          scopedSlots: {
            customRender: 'incidents',
          },
        },
        {
          title: 'Tipo de Manejo',
          dataIndex: 'disposalTypes',
          key: 'disposalTypes',
          class: 'small text-center',
          scopedSlots: {
            customRender: 'disposal',
          },
        },
        {
          title: 'Acciones',
          dataIndex: '',
          class: 'small text-center',
          scopedSlots: { customRender: 'actions' },
        },
      ],
    }
  },
  computed: {
    ...mapGetters('servicePlannings', ['LOCK_SERVICE']),
  },
  methods: {
    handleSearch (selectedKeys, confirm) {
      confirm()
      this.searchText = selectedKeys[0]
    },
    handleReset (clearFilters) {
      clearFilters()
      this.searchText = ''
    },
    refreshResults(paginationEvent = {}) {
      this.$emit('Filter', paginationEvent)
    },
    showModal(action, data = {}) {
      this.$emit('Modal', action, data)
    },
    deleteRecord(data = {}) {
      this.$emit('DeleteRecord', data)
    },
    checkUpdate(checkValue, checkType, data = {}) {
      this.$emit('CheckUpdate', checkValue, checkType, data)
    },
  },
}
</script>

<style scoped>

</style>
