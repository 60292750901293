<template>
  <div>
    <div class="row justify-content-center pt-2 pb-1 mb-4 bg-success rounded text-white">
      <div class="col-sm-12 col-md-5 col-lg-3">
        <strong>No. Programación SAP:</strong>
        {{ currentService.sap_programming_code }}
      </div>
      <div class="col-sm-12 col-md-6 col-lg-7" v-if="Object.keys(currentService).length > 0">
        <strong>Generador:</strong>
        <generatorLogicInfo :service="currentService"/>
      </div>
      <div class="col-sm-12 col-md-4 col-lg-2 align-content-end">
        <b-button-toolbar aria-label="Navegación del Servicio">
          <b-button-group class="mx-1" size="sm">
            <!-- GENERACION DEL SERVICIO-->
            <b-button title="Generación del Servicio"
                      :variant="currentRoute === 'service' ? activeButtonColor : defaultButtonColor "
                      :disabled="currentRoute === 'service' "
                      :pressed="currentRoute === 'service'"
                      @click="clickService">
              <b-iconstack font-scale="3" :variant="currentRoute === 'service' ? activeIconColor : defaultIconColor ">
                <b-icon stacked icon="clock-fill"  scale="0.5" shift-v="-2"/>
                <b-icon stacked icon="calendar" />
              </b-iconstack>
            </b-button>
            <!-- GENERACION DEL SERVICIO-->
            <!-- RECEPCION DEL SERVICIO-->
            <b-button title="Recepción del Servicio"
                      :variant="currentRoute === 'reception_service_plannings' ? activeButtonColor : defaultButtonColor "
                      :disabled="currentRoute === 'reception_service_plannings' "
                      :pressed="currentRoute === 'reception_service_plannings'"
                      @click="clickButton('reception_service_plannings')">
              <b-iconstack font-scale="3" :variant="currentRoute === 'reception_service_plannings' ? activeIconColor : defaultIconColor ">
                <b-icon stacked icon="person-fill" />
              </b-iconstack>
            </b-button>
            <!-- RECEPCION DEL SERVICIO-->
            <!-- DESCARGA DEL SERVICIO-->
            <b-button title="Descarga del Servicio"
                      :variant="currentRoute === 'discharge_service_plannings' ? activeButtonColor : defaultButtonColor "
                      :disabled="currentRoute === 'discharge_service_plannings' "
                      :pressed="currentRoute === 'discharge_service_plannings'"
                      @click="clickButton('discharge_service_plannings')">
              <b-iconstack font-scale="3" :variant="currentRoute === 'discharge_service_plannings' ? activeIconColor : defaultIconColor ">
                <b-icon stacked icon="truck" />
              </b-iconstack>
            </b-button>
            <!-- DESCARGA DEL SERVICIO-->
            <!-- CONTABILIZACION DEL SERVICIO-->
            <!-- CONTABILIZACION DEL SERVICIO-->
          </b-button-group>
        </b-button-toolbar>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex'
import generatorLogicInfo from '@/views/geocycle/service_plannings/components/generatorLogicInfo'

export default {
  name: 'servicePlanningNavToolbar',
  components: {
    generatorLogicInfo,
  },
  props: {
    serviceId: {
      type: [String],
      required: true,
    },
  },
  computed: {
    currentService() {
      return this.$store.state.servicePlannings.currentService
    },
    currentRoute() {
      const route = this.$route.name
      return route
    },
  },
  data() {
    return {
      activeButtonColor: 'secondary',
      activeIconColor: 'light',
      defaultButtonColor: 'outline-default',
      defaultIconColor: 'dark',
    }
  },
  methods: {
    ...mapActions('servicePlannings', ['SET_SERVICEPLANNINGID', 'GOTO_SERVICEPLANNINGID']),
    clickService() {
      this.SET_SERVICEPLANNINGID(this.serviceId)
      this.$router.push({ name: 'service', params: { folio: this.serviceId, id: this.serviceId } })
    },
    clickButton(origin) {
      this.GOTO_SERVICEPLANNINGID({
        id: this.serviceId,
        name: origin,
      })
    },
  },
}
</script>

<style scoped>

</style>
