<template>
  <div>
    <template v-if="jsonApiVuex">
      <span v-if="service.service_planeable.jsonApi.type === 'receivers'">
        {{service.service_planeable.code}} -
        {{service.service_planeable.name}}
      </span>
      <span v-else-if="service.service_planeable.jsonApi.type === 'clients'">
              {{service.service_planeable.jsonApi.sap_code}} -
              {{service.service_planeable.jsonApi.code}} -
              {{service.service_planeable.jsonApi.name}}
            </span>
      <template v-else-if="service.service_planeable.jsonApi.type === 'clientContracts'">
        <strong>Contrato:</strong>{{service.service_planeable.contract_code}}
        <br>
        <strong>Cliente:</strong>
        {{service.service_planeable.jsonApi.meta.clientInfo}}
      </template>
      <span v-else-if="service.service_planeable.jsonApi.type === 'buyProviders'">
              {{service.service_planeable.jsonApi.code}} - {{service.service_planeable.jsonApi.name}}
      </span>
      <span v-else-if="service.service_planeable.jsonApi.type === 'internalClients'">
                <strong>Cliente Interno:</strong>
              {{service.service_planeable.jsonApi.code}} - {{service.service_planeable.jsonApi.name}}
      </span>
      <span v-else>NO INFO</span>
    </template>
    <template v-else>
      <span v-if="service.service_planeable_type === 'App\\Models\\Receivers'">
        {{service.service_planeable.code}} -
        {{service.service_planeable.name}}
      </span>
      <span v-else-if="service.service_planeable_type === 'App\\Models\\Clients'">
            {{service.service_planeable.sap_code}} -
            {{service.service_planeable.code}} -
            {{service.service_planeable.name}}
          </span>
      <template v-else-if="service.service_planeable_type === 'App\\Models\\ClientContract'">
        <strong>Contrato:</strong>{{service.service_planeable.contract_code}}
        <br>
        <strong>Cliente:</strong>
        {{service.service_planeable.meta.clientInfo}}
      </template>
      <span v-else-if="service.service_planeable_type === 'App\\Models\\BuyProviders'">
            {{service.service_planeable.code}} - {{service.service_planeable.name}}
      </span>
      <span v-else-if="service.service_planeable_type === 'App\\Models\\InternalClient'">
              <strong>Cliente Interno:</strong>
            {{service.service_planeable.code}} - {{service.service_planeable.name}}
      </span>
      <span v-else>NO INFO</span>
    </template>
  </div>
</template>

<script>
export default {
  name: 'generatorLogicInfo',
  props: {
    service: {
      type: [Object],
      required: true,
    },
    jsonApiVuex: {
      type: [Boolean],
      required: false,
      default: true,
    },
  },
  data() {
    return {
      ClientContract: 'App\\Models\\ClientContract',
    }
  },
}
</script>

<style scoped>

</style>
